import React from 'react';

export default function ResourceSubmissionSixIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.298 16.5005H27.2317V17.4313H17.298V27.0825H16.3672V17.4313V16.5005H17.298ZM16.3672 37.6648V47.8811V48.8119H17.298H27.2317V47.8811H17.298V37.6648H16.3672ZM47.7478 37.6648V47.8811H37.814V48.8119H47.7478H48.6786V47.8811V37.6648H47.7478ZM48.6786 27.0825V17.4313V16.5005H47.7478H37.814V17.4313H47.7478V27.0825H48.6786Z"
        fill="black"
      />
      <path
        d="M28.357 26.0299L40.5187 33.0515L28.357 40.0731L28.357 26.0299Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.494 12.6273L12.494 12.6273C17.8813 7.23824 25.0433 4.27205 32.6626 4.27205C40.2819 4.27205 47.4455 7.23824 52.8329 12.6273L52.9036 12.5566L52.8329 12.6273C58.2202 18.0146 61.1881 25.1783 61.1881 32.7976C61.1881 40.4168 58.2219 47.5805 52.8329 52.9679L52.9036 53.0386L52.8329 52.9679C47.4455 58.3552 40.2818 61.3231 32.6626 61.3231C25.0433 61.3231 17.8796 58.3569 12.4923 52.9679C7.10494 47.5788 4.13875 40.4152 4.13875 32.7959C4.13875 25.1766 7.10494 18.0146 12.494 12.6273ZM32.6626 3.40049C16.4276 3.40049 3.26719 16.561 3.26719 32.7959C3.26719 49.0308 16.4276 62.1913 32.6626 62.1913C48.8975 62.1913 62.058 49.0308 62.058 32.7959C62.058 16.561 48.8975 3.40049 32.6626 3.40049Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
