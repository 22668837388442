import React from 'react';
import { useHistory } from 'react-router-dom';

import { CompanyIcon, DisciplineIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import PersonIcon from 'app/components/shared/icons/person-icon';
import useBehavioral from 'hooks/useBehavioral';

import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import useDisciplineDrawerData from '../hooks/useDisciplineDrawerData';
import {
  GlobalDisciplineDrawerCounter,
  GlobalDisciplineDrawerCounterContainer,
  GlobalDisciplineDrawerCounterIconContainer,
  GlobalDisciplineDrawerCountersWrapper,
  GlobalDisciplineDrawerCounterText,
  GlobalDisciplineDrawerHighlightText,
  GlobalDisciplineDrawerHighlightTextContainer,
} from './styled';

interface GlobalDisciplineDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
}

export default function GlobalDisciplineDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
}: GlobalDisciplineDrawerProps) {
  const query = useDisciplineDrawerData(slug);
  const { isLoading, data } = query;
  const { push } = useHistory();

  const handleButtonClick = () => {
    push(`/discipline/${slug}`);
  };

  return (
    <GlobalDataTypeDrawer
      slug={slug}
      query={query}
      isLoading={isLoading}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(156, 202, 255, 0.3) 100%), #FFFFFF'
      }
      dataType={'discipline'}
      title={data?.name || ''}
      includesCurrentPerson={data?.includes_current_person || false}
      icon={<DisciplineIcon fill={'#5478A1'} />}
      catchphraseHighlightColor={'#5478A1'}
      iconBackground={'#9CCAFF'}
      buttonOnClick={handleButtonClick}
      buttonBackgroundColor={'#5478A1'}
      comingFromList={comingFromList}
      backToListAction={() => backToListAction()}
    >
      {data?.highlights && (
        <GlobalDisciplineDrawerHighlightTextContainer>
          <GlobalDisciplineDrawerHighlightText>
            <span className="people">
              {data?.highlights?.people?.count}{' '}
              {data?.highlights?.people?.count > 1 ? 'people' : 'person'}
            </span>{' '}
            from{' '}
            <span className="company">
              {data?.highlights?.total_companies == data.highlights?.companies?.count
                ? 'all'
                : data?.highlights?.companies?.count}
              {data?.highlights?.companies?.count > 1 ? ' companies ' : ' company '}
            </span>
            are in the {data?.name} discipline.{' '}
            {data?.highlights?.is_top_discipline && (
              <>
                This is one of the <span className="company">top 5 largest disciplines</span> in the
                Collective.{' '}
              </>
            )}
            {data.highlights?.projects?.active_count > 0 ? (
              <>
                <span className="people">
                  {Math.round(
                    (data?.highlights?.people?.connected_to_active_projects /
                      data?.highlights?.people?.count) *
                      100
                  )}
                  % of people
                </span>{' '}
                in this discipline are connected to{' '}
                <span className="projects">
                  {data?.highlights?.projects?.active_count} active{' '}
                  {data?.highlights?.projects?.active_count > 1 ? 'projects' : 'project'}
                </span>
                .
              </>
            ) : data?.highlights?.projects?.inactive_count > 0 ? (
              <>
                People in this discipline are connected to{' '}
                <span className="projects">
                  {data?.highlights?.projects?.inactive_count}
                  {data?.highlights?.projects?.inactive_count > 1 ? ' projects' : ' project'}
                </span>
                .
              </>
            ) : (
              <></>
            )}
          </GlobalDisciplineDrawerHighlightText>
        </GlobalDisciplineDrawerHighlightTextContainer>
      )}
      <GlobalDisciplineDrawerCountersWrapper>
        {data?.counters.people ? (
          <GlobalDisciplineDrawerCounterContainer>
            <GlobalDisciplineDrawerCounterIconContainer>
              <PersonIcon width={24} height={24} fill={'#FFC229'} />
              <GlobalDisciplineDrawerCounter>{data.counters.people}</GlobalDisciplineDrawerCounter>
            </GlobalDisciplineDrawerCounterIconContainer>
            <GlobalDisciplineDrawerCounterText>
              {data.counters.people !== 1 ? 'people' : 'person'}
            </GlobalDisciplineDrawerCounterText>
          </GlobalDisciplineDrawerCounterContainer>
        ) : null}
        {data?.counters.companies ? (
          <GlobalDisciplineDrawerCounterContainer>
            <GlobalDisciplineDrawerCounterIconContainer>
              <CompanyIcon width={24} height={24} fill={'#5478A1'} />
              <GlobalDisciplineDrawerCounter>
                {data.counters.companies}
              </GlobalDisciplineDrawerCounter>
            </GlobalDisciplineDrawerCounterIconContainer>
            <GlobalDisciplineDrawerCounterText>
              {data.counters.companies !== 1 ? 'companies' : 'company'}
            </GlobalDisciplineDrawerCounterText>
          </GlobalDisciplineDrawerCounterContainer>
        ) : null}
      </GlobalDisciplineDrawerCountersWrapper>
    </GlobalDataTypeDrawer>
  );
}
