import React from 'react';

export default function ResourceSubmissionFourWinnerIcon({ width = 78, height = 93 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 78 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7097_16908)">
        <path
          d="M35.7636 2.88524C37.4398 0.518269 40.9522 0.518269 42.6284 2.88524C44.288 5.22882 47.4903 5.86581 49.9204 4.33572C52.3748 2.79036 55.6198 4.13451 56.2626 6.96275C56.899 9.76303 59.6138 11.577 62.4445 11.0934C65.3034 10.6049 67.7871 13.0885 67.2986 15.9475C66.815 18.7781 68.6289 21.4929 71.4292 22.1294C74.2575 22.7721 75.6016 26.0172 74.0563 28.4716C72.5262 30.9017 73.1631 34.104 75.5067 35.7636C77.8737 37.4398 77.8737 40.9522 75.5067 42.6284C73.1631 44.288 72.5262 47.4903 74.0563 49.9204C75.6016 52.3748 74.2575 55.6199 71.4292 56.2626C68.6289 56.899 66.815 59.6138 67.2986 62.4445C67.7871 65.3034 65.3034 67.7871 62.4445 67.2986C59.6138 66.815 56.899 68.6289 56.2626 71.4292C55.6199 74.2575 52.3748 75.6016 49.9204 74.0563C47.4903 72.5262 44.288 73.1631 42.6284 75.5067C40.9522 77.8737 37.4398 77.8737 35.7636 75.5067C34.104 73.1631 30.9017 72.5262 28.4716 74.0563C26.0172 75.6016 22.7721 74.2575 22.1294 71.4292C21.4929 68.6289 18.7781 66.815 15.9475 67.2986C13.0886 67.7871 10.6049 65.3034 11.0934 62.4445C11.577 59.6138 9.76303 56.899 6.96275 56.2626C4.13451 55.6198 2.79036 52.3748 4.33572 49.9204C5.86581 47.4903 5.22882 44.288 2.88524 42.6284C0.518269 40.9522 0.518269 37.4398 2.88524 35.7636C5.22882 34.104 5.86581 30.9017 4.33572 28.4716C2.79036 26.0172 4.13451 22.7721 6.96275 22.1294C9.76303 21.4929 11.577 18.7781 11.0934 15.9475C10.6049 13.0886 13.0886 10.6049 15.9475 11.0934C18.7781 11.577 21.4929 9.76303 22.1294 6.96275C22.7721 4.13451 26.0172 2.79036 28.4716 4.33572C30.9017 5.86581 34.104 5.22882 35.7636 2.88524Z"
          fill="#F15A42"
          stroke="white"
          strokeWidth="1.2"
        />
        <path
          d="M38.8762 78.2524L21.3969 86.7245V51.6H56.8789V86.7245L39.3996 78.2524L39.1379 78.1255L38.8762 78.2524Z"
          fill="#F15A42"
          stroke="white"
          strokeWidth="1.2"
        />
        <circle cx="39" cy="38" r="29" fill="#F15A42" />
        <path
          d="M19.0527 18.1622L19.0528 18.1621C24.4307 12.7825 31.58 9.82157 39.186 9.82157C46.792 9.82157 53.943 12.7825 59.3209 18.1621L59.427 18.0561L59.321 18.1621C64.6989 23.5401 67.6615 30.6911 67.6615 38.2971C67.6615 45.9031 64.7006 53.054 59.321 58.432L59.427 58.5381L59.321 58.432C53.943 63.81 46.792 66.7726 39.186 66.7726C31.58 66.7726 24.4291 63.8117 19.0511 58.432C13.6731 53.0524 10.7122 45.9014 10.7122 38.2954C10.7122 30.6894 13.6731 23.5401 19.0527 18.1622ZM39.186 8.85C22.9235 8.85 9.74062 22.0328 9.74062 38.2954C9.74062 54.558 22.9235 67.7408 39.186 67.7408C55.4486 67.7408 68.6314 54.558 68.6314 38.2954C68.6314 22.0328 55.4486 8.85 39.186 8.85Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
        <circle cx="31.2949" cy="29.7949" r="11.1949" stroke="white" strokeWidth="1.2" />
        <path
          d="M58.8982 29.7949C58.8982 35.9538 53.6808 40.9897 47.1905 40.9897C40.7002 40.9897 35.4828 35.9538 35.4828 29.7949C35.4828 23.6359 40.7002 18.6 47.1905 18.6C53.6808 18.6 58.8982 23.6359 58.8982 29.7949Z"
          stroke="white"
          strokeWidth="1.2"
        />
        <circle cx="31.2949" cy="46.205" r="11.1949" stroke="white" strokeWidth="1.2" />
        <path
          d="M58.8982 46.205C58.8982 52.364 53.6808 57.3999 47.1905 57.3999C40.7002 57.3999 35.4828 52.364 35.4828 46.205C35.4828 40.0461 40.7002 35.0102 47.1905 35.0102C53.6808 35.0102 58.8982 40.0461 58.8982 46.205Z"
          stroke="white"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_7097_16908">
          <rect width="78" height="93" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
