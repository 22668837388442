import React from 'react';

export default function ResourceSubmissionNineIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="32.5234"
        y="10.8656"
        width="31.3344"
        height="31.3344"
        transform="rotate(45 32.5234 10.8656)"
        stroke="black"
        strokeWidth="1.2"
      />
      <rect
        x="32.5234"
        y="23.9564"
        width="12.8211"
        height="12.8211"
        transform="rotate(45 32.5234 23.9564)"
        stroke="black"
        strokeWidth="1.2"
      />
      <rect
        x="42.7672"
        y="22.6171"
        width="20.8"
        height="20.8"
        transform="rotate(90 42.7672 22.6171)"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.494 13.1439L12.494 13.1439C17.8813 7.75484 25.0433 4.78866 32.6626 4.78866C40.2819 4.78866 47.4455 7.75484 52.8329 13.1439L52.9036 13.0732L52.8329 13.1439C58.2202 18.5312 61.1881 25.6949 61.1881 33.3142C61.1881 40.9334 58.2219 48.0971 52.8329 53.4845L52.9036 53.5552L52.8329 53.4845C47.4455 58.8718 40.2818 61.8397 32.6626 61.8397C25.0433 61.8397 17.8796 58.8735 12.4923 53.4845C7.10494 48.0954 4.13875 40.9318 4.13875 33.3125C4.13875 25.6932 7.10494 18.5312 12.494 13.1439ZM32.6626 3.91709C16.4276 3.91709 3.26719 17.0776 3.26719 33.3125C3.26719 49.5474 16.4276 62.7079 32.6626 62.7079C48.8975 62.7079 62.058 49.5474 62.058 33.3125C62.058 17.0776 48.8975 3.91709 32.6626 3.91709Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
