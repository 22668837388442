import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getDisciplineDrawerData } from 'services/app';

export default function useDisciplineDrawerData(
  slug: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `discipline-${slug}-drawer-data`,
    () =>
      getDisciplineDrawerData(slug).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError?.message);
        }

        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
