import React from 'react';

export default function ResourceSubmissionThreeIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5428 23.1471L32.6406 10.8286L50.7385 23.1471L32.6406 35.4656L14.5428 23.1471Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M51.3928 23.1472L51.3928 43.1393L32.6406 55.9032L13.8885 43.1393L13.8885 23.1472"
        stroke="black"
        strokeWidth="1.2"
      />
      <path d="M32.6016 35.4346V56.1206" stroke="black" strokeWidth="1.2" />
      <path
        d="M12.744 13.1097L12.744 13.1097C18.1313 7.72066 25.2933 4.75448 32.9126 4.75448C40.5319 4.75448 47.6955 7.72066 53.0829 13.1097L53.1536 13.039L53.0829 13.1097C58.4702 18.4971 61.4381 25.6607 61.4381 33.28C61.4381 40.8993 58.4719 48.0629 53.0829 53.4503L53.1536 53.521L53.0829 53.4503C47.6955 58.8376 40.5318 61.8055 32.9126 61.8055C25.2933 61.8055 18.1296 58.8393 12.7423 53.4503C7.35494 48.0613 4.38875 40.8976 4.38875 33.2783C4.38875 25.659 7.35494 18.4971 12.744 13.1097ZM32.9126 3.88291C16.6776 3.88291 3.51719 17.0434 3.51719 33.2783C3.51719 49.5133 16.6776 62.6737 32.9126 62.6737C49.1475 62.6737 62.308 49.5133 62.308 33.2783C62.308 17.0434 49.1475 3.88291 32.9126 3.88291Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
