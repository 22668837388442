import { ResourceCardWrapper } from 'app/components/Resources/ResourceCard/styled';
import {
  StoriesHighlightsContainer,
  StoriesHighlightsTitle,
} from 'app/components/StoriesAndHighlights/styled';
import styled, { css } from 'styled-components';
import {
  slideToRightAndLeftCardAnimation,
  slideToRightAndLeftExtraCardAnimation,
  slideToLeftAndRightCardAnimation,
  slideToLeftAndRightExtraCardAnimation,
  rotateAnimation,
} from 'styles/animations';
import {
  appearAnimation,
  disappearAnimation,
  expandAnimation,
  slideFromRightAnimation,
  slideFromTopAnimation,
  slideToRightAnimation,
  slideToTopAnimation,
  infiniteRotationAnimation,
} from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

export type CommonGroundType =
  | 'languages'
  | 'locations'
  | 'passions'
  | 'industries'
  | 'kyu_skills'
  | 'clients'
  | 'projects'
  | 'more'
  | 'roles';

export const Ref = styled.div`
  width: 100%;
`;

export const CommonGroundWrapper = styled.div`
  width: 100%;
  padding: 0px 50px;

  ${({ theme }) =>
    theme.forceMobile &&
    `
    padding: 0px 16px;
  `}

  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
  }
`;

export const CommonGroundPeopleTooltipContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding: 8px 16px;
  background: #ffffff;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);
  border-radius: 4px;

  & :not(:last-child) {
    margin-left: -8px;
  }
`;

export const CommonGroundPeopleTooltipAvatar = styled.img`
  border: 2px solid #ffc229;
  width: 22px;
  height: 22px;

  border-radius: 1000px;
  object-fit: cover;
`;

export const CommonGroundPeopleTooltipMorePeople = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 22px;
  height: 22px;

  background: rgba(255, 194, 41, 0.15);

  border: 2px solid #ffc229;
  border-radius: 1000px;
`;

export const CommonGroundPeopleTooltipMorePeopleText = styled.p`
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  color: #ffc229;
`;

export const CommonGroundContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 175px;
  }

  width: 100%;
  padding-left: 68px;

  ${({ theme }) =>
    theme.forceMobile &&
    `
    flex-direction: column;
    padding: 56px 15px 24px 15px;

    & > :not(:last-child) {
      margin-right: 16px;
    }
  `}

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    padding: 56px 15px 24px 15px;

    & > :not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const CommonGroundTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) =>
    theme.forceMobile &&
    `
    align-items: center;
  `}

  ${BREAKPOINTS.tablet} {
    align-items: center;
  }
`;

export const CommonGroundTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

const commonGroundCounterColors = {
  languages: '#FFC229',
  locations: '#FFC229',
  passions: '#D47563',
  industries: '#8DA76C',
  kyu_skills: '#EB823D',
  clients: '#104D5B',
  projects: '#392B62',
};

interface CommonGroundCounterProps {
  type: CommonGroundType;
}

export const CommonGroundCounter = styled.h3<CommonGroundCounterProps>`
  color: ${({ type }) => commonGroundCounterColors[type]};
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 83px;
`;

export interface CommonGroundDataWrapperProps {
  gap?: number;
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
}

export const CommonGroundDataWrapper = styled.div<CommonGroundDataWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}

  & > :not(:last-child) {
    margin-right: ${({ gap }) => gap || 8}px;
    margin-bottom: ${({ gap }) => gap || 8}px;
  }

  ${({ theme }) =>
    theme.forceMobile &&
    `
    justify-content: center;
  `}

  ${BREAKPOINTS.tablet} {
    justify-content: center;
  }
`;

interface CommonGroundDataProps {
  type: CommonGroundType;
}

const commonGroundPillColors = {
  passions: 'rgba(212, 117, 99, 0.3)',
  industries: 'rgba(141, 167, 108, 0.3)',
  kyu_skills: 'rgba(235, 130, 61, 0.3)',
  clients: 'rgba(16, 77, 91, 0.3)',
  projects: 'rgba(57, 43, 98, 0.3)',
  roles: 'rgba(255, 255, 255, 1)',
};

const commonGroundPillTextColors = {
  passions: '#D47563',
  industries: '#8DA76C',
  kyu_skills: '#EB823D',
  clients: '#104D5B',
  projects: '#392B62',
  roles: '#1F2025',
};

export const CommonGroundData = styled.div<CommonGroundDataProps>`
  ${({ type }) => {
    return !['locations', 'languages'].includes(type)
      ? `
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > :not(:last-child) {
        margin-right: 5px;
      }

      padding: ${type == 'roles' ? `8px 24px` : `2px 8px`};
      border-radius: 26px;
      background-color: ${commonGroundPillColors[type]};

      & > span {
        color: ${commonGroundPillTextColors[type]};
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }

      ${BREAKPOINTS.tablet} {
        padding: ${type == 'roles' ? `8px 10px` : `2px 8px`};
      }
    `
      : `
      cursor: auto!important;
      margin-bottom: 20px!important;
      margin-right: 20px!important;
    `;
  }}

  ${({ type }) => {
    if (type === 'more') {
      return `
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 8px;
        & > :not(:last-child) {
          margin-right: 5px;
        }
        background: #F7F7F7;
        backdrop-filter: blur(4px);
        border-radius: 26px;

        & > span {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: rgba(23, 28, 51, 0.5);
        }
      `;
    }
  }}
`;

interface CompanyDetailsHeroWrapperProps {
  background?: string;
}

export const CompanyDetailsHeroWrapper = styled.div<CompanyDetailsHeroWrapperProps>`
  background-image: ${({ background }) =>
    background ? 'url(' + background + ')' : 'url(/images/kyu/kyu-hero-image.jpg)'};
  width: 100%;
  min-height: 406px;
  position: relative;
  z-index: 0;

  padding: 75px 48px 0px 75px;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(31, 32, 37, 0.45);
    backdrop-filter: blur(11px);
    z-index: -1;

    ${BREAKPOINTS.tablet} {
      width: 100%;
    }
  }

  ${BREAKPOINTS.tablet} {
    padding: 24px 16px 16px 16px;
    width: 100%;
  }
`;

export const CompanyDetailsHeroTitle = styled.h1`
  color: #ffffff;
  font-weight: 300;
  font-size: 64px;
  line-height: 83px;

  @media (max-width: 1640px) {
    ${({ theme }) =>
      theme.data_health &&
      `
    max-width: 50%;
  `}
  }

  ${BREAKPOINTS.tablet} {
    font-size: 38px;
    line-height: 42px;
    max-width: 100%;
  }
`;

export const CompanyDetailsHeroBioWrapper = styled.div`
  margin-top: 34px;
  max-width: 50%;

  ${BREAKPOINTS.tablet} {
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
  }
`;

export const CompanyDetailsHeroBio = styled.h1`
  color: #ffffff;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;

  ${BREAKPOINTS.tablet} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const CompanyDetailsHeroDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 64px;
  position: absolute;
  bottom: 32px;
  max-width: calc(100% - 423px);

  & > :not(:last-child) {
    margin-right: 32px;
    ${BREAKPOINTS.tablet} {
      margin-right: 16px;
    }
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    max-width: 50%;
    position: relative;
    margin-top: 25px;
    bottom: 0px;
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
    max-width: 100%;
    margin-top: 16px;
    position: relative;
    margin-top: 20px;
    bottom: 0px;
  }
`;

export const CompanyDetailsHeroDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  ${BREAKPOINTS.large_tablet_landscape} {
    margin-bottom: 8px;
  }

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const CompanyDetailsHeroDetailTitle = styled.span`
  color: #ffffff;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
`;

export const CompanyDetailsHeroDetailURL = styled.a`
  color: #ffffff;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
`;

interface CompanyDetailsHeroFeatureBlockContainerFrameProps {
  dataHealthPresent: boolean;
}

export const CompanyDetailsHeroFeatureBlockContainerFrame = styled.div<CompanyDetailsHeroFeatureBlockContainerFrameProps>`
  position: absolute;
  width: ${({ dataHealthPresent }) => (dataHealthPresent ? '280px' : '350px')};
  height: ${({ dataHealthPresent }) => (dataHealthPresent ? '280px' : '255px')};
  right: ${({ dataHealthPresent }) => (dataHealthPresent ? '58px' : '48px')};
  bottom: ${({ dataHealthPresent }) => (dataHealthPresent ? '-82px' : '-24px')};
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  ${BREAKPOINTS.tablet} {
    display: none;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    width: 300px;
    height: 200px;
  }
`;

interface CompanyDetailsHeroFeatureBlockContainerProps {
  dataHealthPresent: boolean;
}

export const CompanyDetailsHeroFeatureBlockContainer = styled.div`
  position: absolute;
  width: ${({ dataHealthPresent }) => (dataHealthPresent ? '256px' : '326px')};
  height: ${({ dataHealthPresent }) => (dataHealthPresent ? '256px' : '231px')};
  right: ${({ dataHealthPresent }) => (dataHealthPresent ? '70px' : '60px')};
  bottom: ${({ dataHealthPresent }) => (dataHealthPresent ? '-70px' : '-12px')};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: #5478a1;

  border-radius: 10px;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    width: 276px;
    height: 176px;
  }

  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const CompanyDetailsHeroFeatureBlock = styled.div``;

export const CompanyDetailsHeroFeatureBlockTitle = styled.h3`
  color: #ffffff;
  font-weight: 300;
  font-size: 38px;
  line-height: 42px;

  text-align: center;
`;

export const CompanyDetailsHeroFeatureBlockCounter = styled.h3`
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

interface CompanyDetailsHeroAnnouncementsContainerProps {
  dataHealthPresent: boolean;
}

export const CompanyDetailsHeroAnnouncementsContainer = styled.div`
  display: flex;
  position: absolute;
  right: ${({ dataHealthPresent }) => (dataHealthPresent ? '22px' : '48px')};
  bottom: ${({ dataHealthPresent }) => (dataHealthPresent ? '-45px' : '-12px')};

  ${BREAKPOINTS.tablet} {
    position: relative;
    right: 0px;
    bottom: 0px;
    margin: 10px 0px;
  }
`;

export const CompanyDetailsSnapshotWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 48px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 16px;

    & > :not(:last-child) {
      margin-bottom: 54px;
    }
  }
`;

export const CompanyDetailsSnapshotHighlightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 600px;

  & > :not(:last-child) {
    margin-bottom: 27px;
  }

  ${BREAKPOINTS.tablet} {
    margin: 0px;
    text-align: start;
    align-items: flex-start;

    & > :not(:last-child) {
      margin-bottom: 18px;
    }
  }
`;

export const CompanyDetailsSnapshotHighlight = styled.p`
  font-weight: 300;
  font-size: 21px;
  line-height: 27px;

  ${BREAKPOINTS.tablet} {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const CompanyDetailsSnapshotHighlightCount = styled.span`
  color: #ffc229;
  border-bottom: 1px solid #ffc229;
  font-weight: 300;
  font-size: 21px;
  line-height: 27px;

  ${BREAKPOINTS.tablet} {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
  cursor: pointer;
`;

export const CompanyDetailsSnapshotHighlightSkill = styled.span`
  color: #eb823d;
  border-bottom: 1px solid #eb823d;
  font-weight: 300;
  font-size: 21px;
  line-height: 27px;
  cursor: pointer;

  ${BREAKPOINTS.tablet} {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const CompanyDetailsSnapshotRelatedCompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${BREAKPOINTS.tablet} {
    align-self: flex-start;
  }
`;

export const CompanyDetailsSnapshotRelatedCompaniesTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const CompanyDetailsSnapshotRelatedCompaniesTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
`;

export const CompanyDetailsSnapshotRelatedCompaniesContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  & :not(:last-child) {
    margin-left: -16px;
  }

  ${BREAKPOINTS.tablet} {
    justify-content: flex-end;
  }
`;

export const CompanyDetailsSnapshotRelatedCompaniesImage = styled.img`
  width: 64px;
  height: 64px;

  background: #ffffff;

  border: 2px solid #5478a1;
  border-radius: 1000px;

  ${BREAKPOINTS.tablet} {
    width: 32px;
    height: 32px;
  }
`;

export const CompanyDetailsBubbleChartWrapper = styled.div`
  width: 100%;

  & > div {
    margin: auto;
  }
`;

export const CompanyDetailsBubbleChartTitle = styled.h3`
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 400px;
  padding: 0px 48px;
  text-align: left;
  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
  }
`;

export const CompanyDetailsMapSnippetTitle = styled.h3`
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 400px;
  padding: 0px 48px;
  ${BREAKPOINTS.mobile} {
    padding: 0px 16px;
  }
`;

export const CompanyDetailsMapSnippetContainer = styled.div`
  width: 100%;

  & > div {
    margin: auto;
  }
`;

export const CompanyDetailsMapSnippetWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 61px 103px;
  position: relative;

  ${BREAKPOINTS.tablet} {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 16px 48px 16px;
    flex-wrap: wrap;

    & > :not(:last-child) {
      margin-bottom: 25px;
    }
  }
`;

export const CompanyDetailsMapSnippetInnerContainer = styled.div`
  width: 478px;
  height: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompanyDetailsMapSnippetIconOverallContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const CompanyDetailsMapSnippetIconWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CompanyDetailsMapSnippetPillMobileWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CompanyDetailsMapSnippetPillMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CompanyDetailsMapSnippetIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 1;
`;

export const CompanyDetailsMapSnippetMainLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  z-index: 1;
`;

export const CompanyDetailsMapSnippetText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  color: #ffffff;

  & > .passion {
    color: var(--color-data-passion-background);
  }

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const CompanyDetailsMapSnippetMainIconShared = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
`;

interface CompanyDetailsMapSnippetPillProps {
  top?: number;
  left?: number;
  position?: 'absolute' | 'relative';
}

export const CompanyDetailsMapSnippetPill = styled.div<CompanyDetailsMapSnippetPillProps>`
  position: ${({ position }) => position ?? 'absolute'};

  ${({ position, top }) => (!position || position === 'absolute') && `top: ${top}%;`}
  ${({ position, left }) => (!position || position === 'absolute') && `left: ${left}%;`}
  background: #F2D5D0;
  border-radius: 26px;
  padding: 2px 8px;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #d47563;
  }

  cursor: pointer;
`;

export const CompanyDetailsMapSnippetPillShared = styled.div`
  position: absolute;
  top: 0;
  right: -27px;
`;

export const CompanyDetailsMapSnippetPillConector = styled.div`
  position: absolute;
  width: 2px;
  margin-top: -1px;
  background: rgba(235, 130, 61, 0.3);
  z-index: 0;
`;

export const CompanyDetailsIntersectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  margin: 0px 156px;

  background: #171c33;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    margin: 0px 16px;
  }

  cursor: pointer;
`;

export const CompanyDetailsIntersectionText = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;

  width: 50%;

  > span {
    font-weight: 700;
    color: #d47563;
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

export const CompanyDetailsIntersectionIconContainer = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #fbafa1;
  border-radius: 100px;
`;

export const CompanyDetailsIntersectionExploreInMap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > span {
    color: #ffffff;
  }
`;

export const CompanyDetailsIntersectionTotalCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  border-radius: 1000px;
  background: #1f2025;
  border: 2px solid #d47563;

  & > span {
    color: #d47563;
  }
`;

export const CompanyDetailsIntersectionMapText = styled.div`
  display: flex;
  align-items: center;

  & > span {
    color: #ffffff;
  }

  & > :not(:last-child) {
    margin-right: 12px;
  }
`;

interface CompanyDetailsBubbleChartCompanyCircleProps {
  backgroundImage: string;
}

export const CompanyDetailsBubbleChartCompanyCircle = styled.div<CompanyDetailsBubbleChartCompanyCircleProps>`
  width: 88px;
  height: 88px;

  background-color: white;
  border: 2px solid #5478a1;
  border-radius: 1000px;

  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
`;

interface CompanyDetailsBubbleProps {
  size: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const CompanyDetailsBubble = styled.div<CompanyDetailsBubbleProps>`
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: left;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  font-size: 14px;
  position: absolute;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${({ top }) => top && `top: ${top}px;`}
  ${({ bottom }) => bottom && `bottom: ${bottom}px;`}
  ${({ left }) => left && `left: ${left}px;`}
  ${({ right }) => right && `right: ${right}px;`}
`;

export const CompanyDetailsBubbleClient = styled(CompanyDetailsBubble)`
  background-color: rgba(16, 77, 91, 0.3);

  & > span {
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    color: #104d5b;
  }
`;

export const CompanyDetailsBubbleIndustry = styled(CompanyDetailsBubble)`
  background-color: rgba(141, 167, 108, 0.3);

  & > span {
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    color: #8da76c;
  }
`;

export const CompanyDetailsBubbleShared = styled.div`
  position: absolute;
  top: 15%;
  right: 3%;
`;

export const CompanyDetailsBubbleChartCirclesWrapper = styled.div`
  flex: 1;
  position: relative;
  margin: 0px 48px;

  & ${CompanyDetailsBubbleChartCompanyCircle} {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    top: ${500 / 2 - 44}px;
    right: 0;
    text-align: center;
  }
`;

export const MemberCompanyDetailsMapSnippetText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  color: #ffffff;

  & > .client {
    color: var(--color-data-client-background);
  }

  & > .industry {
    color: var(--color-data-industry-background);
  }

  & > .company {
    color: var(--color-data-company-background);
  }

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const CompanyDetailsIntersectionCompanyImage = styled.img`
  width: 32px;
  height: 32px;

  background: #ffffff;
  border: 2px solid #5478a1;
  border-radius: 1000px;
`;

export const CompanyDetailsImpactWrapper = styled.div`
  width: 100%;
  padding: 0px 48px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 32px;
  }

  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
  }
`;

export const CompanyDetailsImpactTitle = styled.h3`
  font-weight: 300;
  font-size: 38px;
  line-height: 42px;
  text-transform: capitalize;

  span {
    text-transform: none; 
  }
`;

export const CompanyDetailsImpactSubtitle = styled.h4`
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  color: #171c33;
  text-transform: capitalize;
`;

export const CompanyDetailsImpactParagraph = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
`;

interface CompanyDetailsImpactGraphTooltipProps {
  type: 'people' | 'projects';
}

export const CompanyDetailsImpactGraphTooltip = styled.div<CompanyDetailsImpactGraphTooltipProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;

  background: ${({ type }) =>
    type === 'people' ? 'rgba(255, 194, 41, 0.15)' : 'rgba(57, 43, 98, 0.3)'};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(48px);
  border-radius: 4px;

  & > span {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #171c33;
  }
`;

interface CompanyDetailsImpactHighlightProps {
  borderBottom?: boolean;
}

export const CompanyDetailsImpactHighlight = styled.span<CompanyDetailsImpactHighlightProps>`
  color: ${({ color }) => color};
  ${({ borderBottom }) => borderBottom && `border-bottom: 1px solid`};
  cursor: pointer;
`;

export const CompanyDetailsImpactParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  margin-top: 32px;
`;

export const CompanyDetailsImpactGraphWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  position: relative;
  margin-top: 50px;

  ${BREAKPOINTS.tablet} {
    margin-top: 85px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CompanyDetailsImpactGraphFilterWrapper = styled.div`
  position: absolute;
  top: -30px;
  right: 0;

  ${BREAKPOINTS.tablet} {
    top: -80px;
  }
`;

interface CompanyDetailsImpactGraphFilterProps {
  picked?: boolean;
}

export const CompanyDetailsImpactGraphFilter = styled.span<CompanyDetailsImpactGraphFilterProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171c33;
  opacity: ${({ picked }) => (picked ? `1` : '0.5')};
  cursor: pointer;
`;

export const CompanyDetailsImpactGraphOverallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: relative;
`;

interface CompanyDetailsImpactGraphContainerProps {
  minHeight: number;
}

export const CompanyDetailsImpactGraphContainer = styled.div<CompanyDetailsImpactGraphContainerProps>`
  min-height: ${({ minHeight }) => minHeight}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  border-bottom: 1px solid rgba(23, 28, 51, 0.3);
`;

export const CompanyDetailsImpactGraphBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  & > .person {
    color: #ffc229;
  }

  & > .project {
    color: #392b62;
  }
`;

interface CompanyDetailsImpactGraphBarMobilePercentageIconProps {
  type: 'people' | 'projects';
}

export const CompanyDetailsImpactGraphBarMobilePercentageIcon = styled.div<CompanyDetailsImpactGraphBarMobilePercentageIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: ${({ type }) => (type === 'people' ? '#FFD841' : '#9F7CFB')};
  border-radius: 1000px;
`;

interface CompanyDetailsImpactGraphBarProps {
  height: number;
}

export const CompanyDetailsImpactGraphBar = styled.div<CompanyDetailsImpactGraphBarProps>`
  height: ${({ height }) => height}px;
  width: 16px;
  background-color: ${({ color }) => color};
  border-radius: 4px 4px 0px 0px;
`;

interface CompanyDetailsImpactGraphTitleProps {
  bottom?: number;
}

export const CompanyDetailsImpactGraphTitle = styled.span<CompanyDetailsImpactGraphTitleProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171c33;
  text-align: center;

  margin-top: 14px;

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;

export const CompanyDetailsImpactSeeAllContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 63px;
`;

export const CompanyDetailsImpactSeeAllText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(23, 28, 51, 0.5);
  text-align: center;
`;

export const CompanyDetailsImpactSeeAllTextHighlight = styled(CompanyDetailsImpactSeeAllText)`
  text-decoration-line: underline;
  color: #8da76c;
  cursor: pointer;
`;

export const CompanyDetailsProjectsWrapper = styled.div`
  padding: 0px 48px;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 41px;
  }

  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
  }
`;

export const CompanyDetailsProjectsTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 32px;

  text-transform: capitalize;
`;

export const CompanyDetailsProjectsHeadline = styled.span`
  margin-right: -5px;
`;

export const CompanyDetailsProjectsFilters = styled.span`
  border-bottom: 2px solid #392b62;
  position: relative;
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 6px;
  }
`;

export const CompanyDetailsProjectsDropdownContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 6px;
  }
`;

interface CompanyDetailsProjectsFilterProps {
  show: boolean;
}

export const CompanyDetailsProjectsFilter = styled.span<CompanyDetailsProjectsFilterProps>`
  display: ${({ show }) => (show ? `inline` : `none`)};
  cursor: pointer;
`;

export const CompanyDetailsProjectsDropdownOptionsContainer = styled.div`
  background-color: #fff;
  min-width: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
  padding: 20px 10px;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);

  position: absolute;
  top: -20px;
  left: -9px;

  z-index: 1;
`;

interface CompanyDetailsProjectsDropdownOptionProps {
  selected: boolean;
}

export const CompanyDetailsProjectsDropdownOption = styled.span<CompanyDetailsProjectsDropdownOptionProps>`
  color: rgba(57, 43, 98);
  opacity: ${({ selected }) => (selected ? `0.5` : `1`)};
  cursor: pointer;
`;

export const CompanyDetailsProjectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-bottom: 24px;

    ${BREAKPOINTS.mobile} {
      margin-right: 0px;
    }
  }

  ${BREAKPOINTS.mobile} {
    flex-direction: column;
    margin-right: 0px;
  }
`;

export const CompanyDetailsProject = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  width: 31%;
  margin-right: 2%;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 16px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${BREAKPOINTS.mobile} {
    width: 100%;
    margin-right: 0px;
  }

  cursor: pointer;
`;

export const CompanyDetailsProjectLabel = styled.div`
  position: absolute;
  right: 16px;
  top: 0px;
  padding: 4px 12px;
  text-transform: uppercase;
  background: rgba(159, 124, 251, 0.6);
  border-radius: 0px 0px 16px 16px;

  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

export const CompanyDetailsProjectTitle = styled.h4`
  color: #171c33;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
`;

export const CompanyDetailsProjectCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;

  background: rgba(173, 173, 173, 0.3);
  border-radius: 26px;

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: rgba(23, 28, 51, 0.5);
  }
`;

export const CompanyDetailsProjectClientCompany = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  padding: 2px 8px;
  background: rgba(16, 77, 91, 0.3);
  border-radius: 26px;

  width: fit-content;

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #104d5b;
  }
`;

export const CompanyDetailsProjectTopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const CompanyDetailsProjectSeparator = styled.hr`
  width: 100%;
  color: #adadad;
  opacity: 0.3;
`;

export const CompanyDetailsProjectBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  width: 100%;
`;

export const CompanyDetailsProjectCompanyLogo = styled.img`
  width: 51px;
  height: 51px;

  border: 2px solid #5478a1;
  border-radius: 1000px;
  background-color: #ffffff;
`;

export const CompanyDetailsProjectBottomRightSection = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CompanyDetailsProjectDate = styled.span`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  color: rgba(23, 28, 51, 0.5);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const CompanyDetailsProjectDatesContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: rgba(23, 28, 51, 0.5);
  }
`;

export const CompanyDetailsProjectPeopleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & > * {
    margin-left: -8px;
  }
`;

export const CompanyDetailsProjectPerson = styled.img`
  border: 2px solid #ffc229;
  width: 22px;
  height: 22px;
  border-radius: 1000px;

  object-fit: cover;
`;

export const CompanyDetailsProjectMorePeople = styled.span`
  width: 22px;
  height: 22px;
  background-color: rgba(255, 194, 41, 0.15);
  border: 2px solid #ffc229;
  border-radius: 1000px;

  & > span {
    color: #ffc229;
    font-size: 11px;
    font-weight: 700;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 12px;
`;

export const CompanyDetailsProjectMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 31%;
  margin-right: 2%;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  flex-basis: calc(33.33% - 24px);
  height: 164px;

  background: #f5faff;
  border-radius: 4px;

  ${BREAKPOINTS.mobile} {
    width: 100%;
    margin-right: 0px;
  }
`;

export const CompanyDetailsProjectMoreLogoContainer = styled.div`
  padding: 6px;
  border-radius: 100%;
  background: rgba(159, 124, 251, 0.8);
`;

export const CompanyDetailsProjectMoreTop = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const CompanyDetailsProjectMoreCounter = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  background: rgba(57, 43, 98, 0.3);
  border: 2px solid #392b62;
  border-radius: 1000px;

  & > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;

    text-align: center;
    color: #392b62;
  }
`;

export const CompanyDetailsProjectSeeMoreButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  margin-top: 15px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  width: 140px;
  height: 48px;

  background: #9f7cfb;
  border-radius: 100px;

  cursor: pointer;

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #171c33;
    user-select: none;
  }
`;

export const CompanyDetailsNewPeopleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 0 48px;

  & > :not(:last-child) {
    margin-bottom: 32px;
  }

  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
  }
`;

export const CompanyDetailsNewPeopleTitle = styled.h3`
  font-weight: 300;
  font-size: 38px;
  line-height: 42px;
  color: #171c33;
`;

export const CompanyDetailsNewPeopleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
  }

  & > :not(:last-child) {
    ${BREAKPOINTS.tablet} {
      margin-bottom: 32px;
    }
  }
`;

export const CompanyDetailsNewFacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

export const CompanyDetailsNewFacesSeeMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  cursor: pointer;
`;

interface CompanyDetailsNewFacesSeeMoreContainerProps {
  shouldAddPadding?: boolean;
}

export const CompanyDetailsNewFacesSeeMoreContainer = styled.div<CompanyDetailsNewFacesSeeMoreContainerProps>`
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 1000px;
  border: 2px solid var(--color-data-people-foreground);
  background: var(--color-data-people-background-secondary);

  ${({ shouldAddPadding }) => shouldAddPadding && `padding: 0px 8px;`}
`;

export const CompanyDetailsNewFacesSeeMoreValue = styled.span`
  color: var(--color-data-people-foreground);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
`;

export const CompanyDetailsNewFacesSeeMoreText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);
`;

export const CompanyDetailsNewFacesTitle = styled.h5`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;

  margin-bottom: 25px;

  ${BREAKPOINTS.tablet} {
    align-self: flex-start;
  }
`;

export const CompanyDetailsNewFaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const CompanyDetailsNewFaceImage = styled.img`
  width: 44px;
  height: 44px;
  border: 2px solid #ffc229;
  border-radius: 1000px;
  object-fit: cover;
`;

export const CompanyDetailsNewFaceTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyDetailsNewFaceName = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #171c33;
`;

export const CompanyDetailsNewFacePosition = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #171c33;
`;

export const CompanyDetailsMeetSomeoneNewComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }

  & > div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface CompanyDetailsSomeoneNewWrapperProps {
  fromPassion?: boolean;
  triggerNext?: boolean;
  triggerPrevious?: boolean;
}

export const CompanyDetailsSomeoneNewWrapper = styled.div<CompanyDetailsSomeoneNewWrapperProps>`
  position: relative;
  margin-right: ${({ fromPassion }) => (fromPassion ? '0px' : '118px')};
  align-self: center;

  ${BREAKPOINTS.tablet} {
    margin-right: 0px;
  }
`;

export const CompanyDetailsSomeoneNewBackgroundCard = styled.div`
  position: absolute;
  width: 523px;
  height: 255px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;

  background: #f5faff;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);
  border-radius: 4px;

  & > :not(:last-child) {
    ${BREAKPOINTS.tablet} {
      margin-bottom: 47px;
    }
  }

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 230px;
    height: 290px;
  }

  z-index: 2;
`;

interface CompanyDetailsSomeoneNewBackgroundCardOneProps {
  triggerNext?: boolean;
  triggerPrevious?: boolean;
}

export const CompanyDetailsSomeoneNewBackgroundCardOne = styled(
  CompanyDetailsSomeoneNewBackgroundCard
)<CompanyDetailsSomeoneNewBackgroundCardOneProps>`
  transform: rotate(5deg);

  ${({ triggerPrevious }) => (triggerPrevious ? slideToRightAndLeftExtraCardAnimation : '')}
  ${({ triggerNext }) => (triggerNext ? slideToLeftAndRightExtraCardAnimation : '')}
`;

interface CompanyDetailsSomeoneNewBackgroundCardTwoProps {
  triggerChange?: boolean;
}

export const CompanyDetailsSomeoneNewBackgroundCardTwo = styled(
  CompanyDetailsSomeoneNewBackgroundCard
)<CompanyDetailsSomeoneNewBackgroundCardTwoProps>`
  z-index: 1;

  ${({ triggerChange }) => (triggerChange ? rotateAnimation(5, 1) : '')}
`;

export const SomeoneNewBackgroundCardPersonAvatar = styled.div`
  width: 64px;
  height: 64px;

  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #adadad;

  border-radius: 1000px;
`;

export const SomeoneNewBackgroundCardQuoteSkeletonContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 4px;
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

interface SomeoneNewBackgroundCardQuoteSkeletonProps {
  width: number;
}

export const SomeoneNewBackgroundCardQuoteSkeleton = styled.div<SomeoneNewBackgroundCardQuoteSkeletonProps>`
  width: ${({ width }) => width}%;
  height: 14px;
  background: #d9d9d9;
  border-radius: 100px;
`;

interface CompanyDetailsSomeoneNewContainerProps {
  triggerNext?: boolean;
  triggerPrevious?: boolean;
}

export const CompanyDetailsSomeoneNewContainer = styled.div<CompanyDetailsSomeoneNewContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 48px;

  & > :not(:last-child) {
    margin-right: 26px;
    ${BREAKPOINTS.tablet} {
      margin-right: 0px;
      margin-bottom: 24px;
    }
  }

  width: 523px;
  height: 255px;

  background: #f5faff;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);
  border-radius: 4px;
  position: relative;

  ${BREAKPOINTS.tablet} {
    width: 230px;
    height: 290px;

    flex-direction: column;
    padding: 24px;
    align-items: flex-start;
  }

  z-index: 3;

  ${({ triggerPrevious }) => (triggerPrevious ? slideToRightAndLeftCardAnimation : '')}
  ${({ triggerNext }) => (triggerNext ? slideToLeftAndRightCardAnimation : '')}
`;

export const CompanyDetailsSomeoneNewWildcardContainer = styled.div`
  position: absolute;
  width: 121px;
  height: 122px;
  top: -53px;
  right: -53px;
  z-index: 4;

  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const CompanyDetailsSomeoneNewStartQuoteContainer = styled.div`
  position: absolute;
  top: -20px;
  left: -15px;
  z-index: 1;
`;

export const CompanyDetailsSomeoneNewEndQuoteContainer = styled.div`
  position: absolute;
  bottom: -15px;
  right: -10px;
  z-index: 1;
`;

export const CompanyDetailsSomeoneNewTextContainer = styled.div`
  position: relative;
`;

export const CompanyDetailsSomeoneNewLearnMore = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #ffc229;

  position: absolute;
  bottom: 24px;
  right: 50%;
  transform: translate(50%);

  cursor: pointer;
`;

export const CompanyDetailsSomeoneNewImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${BREAKPOINTS.tablet} {
    align-items: flex-start;
  }
`;

export const CompanyDetailsSomeoneNewImage = styled.img`
  width: 64px;
  height: 64px;
  border: 2px solid #ffc229;

  border-radius: 1000px;
  object-fit: cover;
`;

export const CompanyDetailsSomeoneNewBio = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #171c33;
  word-break: break-word;
`;

export const CompanyDetailsSomeoneNewAuthor = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: rgba(23, 28, 51, 0.5);
`;

export const CompanyDetailsSomeoneNewPrevious = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  left: -16px;
  top: calc(50% - 16px);

  background: #ffffff;
  box-shadow: 0px 0px 10px -10px rgba(50, 63, 93, 0.15);
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 4;
  user-select: none;
  cursor: pointer;
`;

export const CompanyDetailsSomeoneNewNext = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  right: -16px;
  top: calc(50% - 16px);

  background: #ffffff;
  box-shadow: 0px 0px 10px -10px rgba(50, 63, 93, 0.15);
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 4;
  user-select: none;
  cursor: pointer;
`;

export const CompanyDetailsStoryHighlightWrapper = styled.div`
  ${({ theme }) =>
    theme.slug === 'kyu' &&
    `
    ${StoriesHighlightsTitle} {
      font-size: 38px;
    }
  `}

  ${ResourceCardWrapper} {
    max-width: 285px;
  }

  & ${StoriesHighlightsContainer} {
    margin: 0px -48px;
    padding: 0px 48px;

    ${BREAKPOINTS.tablet} {
      margin: 0px -16px;
      padding: 0px 16px;
    }
  }
`;

export const CompanyDetailsPeopleWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 48px;
  margin-top: 76px;

  & > :not(:last-child) {
    margin-bottom: 32px;
  }

  ${BREAKPOINTS.tablet} {
    padding: 0 16px;
  }
`;

export const CompanyDetailsPeopleTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;

  color: #171c33;
`;

export const CompanyDetailsPeopleFilters = styled.span`
  border-bottom: 2px solid #392b62;
  position: relative;
`;

interface CompanyDetailsPeopleFilterProps {
  show: boolean;
}

export const CompanyDetailsPeopleFilter = styled.span<CompanyDetailsPeopleFilterProps>`
  display: ${({ show }) => (show ? 'inline' : 'none')};
  cursor: default;
`;

export const CompanyDetailsPeopleFilterContainer = styled.div`
  background-color: #fff;
  min-width: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  padding: 20px 10px;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);

  position: absolute;
  top: 0px;
  left: 10px;

  z-index: 1;
`;

interface CompanyDetailsPeopleFilterOptionProps {
  selected?: boolean;
}

export const CompanyDetailsPeopleFilterOption = styled.span<CompanyDetailsPeopleFilterOptionProps>`
  color: rgba(57, 43, 98);
  opacity: ${({ selected }) => (selected ? `0.5` : `1`)};
  cursor: default;
`;

export const CompanyDetailsPeopleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
    margin-bottom: 16px;

    ${BREAKPOINTS.mobile} {
      margin-right: 0px;
    }
  }

  ${BREAKPOINTS.tablet} {
    justify-content: space-evenly;
  }
`;

export const CompanyDetailsPeopleCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(33.33% - 16px);
  align-items: flex-start;
  padding: 16px;
  height: fit-content;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  width: 290px;

  background: #f7f7f7;
  border-radius: 4px;

  cursor: pointer;

  ${BREAKPOINTS.tablet} {
    width: 100%;
    flex-basis: 45%;
  }

  ${BREAKPOINTS.mobile} {
    width: 100%;
    flex-basis: 100%;
  }
`;

export const CompanyDetailsPeopleCardTopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const CompanyDetailsPeopleCardBottomSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const CompanyDetailsPeopleCardImage = styled.img`
  height: 64px;
  width: 64px;
  border: 2px solid #ffc229;
  border-radius: 1000px;
  object-fit: cover;
`;

export const CompanyDetailsPeopleCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CompanyDetailsPeopleCardName = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171c33;
`;

export const CompanyDetailsPeopleCardPosition = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: rgba(23, 28, 51, 0.5);
`;

export const CompanyDetailsPeopleSeparator = styled.hr`
  width: 100%;
  height: 1px;
  background: rgba(173, 173, 173, 0.3);
  border: none;
`;

export const CompanyDetailsPeopleLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
  background: rgba(156, 202, 255, 0.4);
  border-radius: 4px;
`;

export const CompanyDetailsPeopleConnectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CompanyDetailsPeopleConnectionCounter = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: #ffc229;
  font-weight: 700;

  & > div {
    font-weight: 400;
  }

  & > :not(:last-child) {
    margin-right: 10px;
  }

  background: rgba(255, 194, 41, 0.15);
  border: 2px solid #ffc229;
  border-radius: 1000px;

  width: 32px;
  height: 32px;

  &:hover {
    div {
      visibility: visible;
    }
    ${BREAKPOINTS.tablet} {
      div {
        visibility: hidden;
      }
    }
  }
`;

export const CompanyDetailsPeopleConnectionTooltip = styled.div`
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 16px;
  top: 30px;
  left: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const CompanyDetailsPeopleConnectionLine = styled.hr`
  border: 1px dashed #ffc229;
  width: 10px;
`;

export const CompanyDetailsPeopleConnectionAvatar = styled.img`
  width: 32px;
  height: 32px;
  border: 2px solid #ffc229;
  border-radius: 1000px;
  object-fit: cover;
`;

export const CompanyDetailsPeopleLocationFlag = styled.span`
  width: 24px;
  height: 24px;
`;

export const CompanyDetailsPeopleLocationName = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #171c33;
`;

export const CompanyDetailsPeopleMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  flex-basis: calc(33.33% - 16px);
  height: 184px;

  background: #f7f7f7;
  border-radius: 4px;

  ${BREAKPOINTS.tablet} {
    flex-basis: 100%;
  }
`;

export const CompanyDetailsPeopleMoreTop = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const CompanyDetailsPeopleMoreLogoContainer = styled.div`
  padding: 6px;
  border-radius: 100%;
  background: rgba(255, 210, 95, 1);

  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompanyDetailsPeopleMoreCounter = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  background: rgba(255, 194, 41, 1);
  border: 2px solid #ffc229;
  border-radius: 1000px;

  & > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;

    text-align: center;
    color: #392b62;
  }
`;

export const CompanyDetailsPeopleMoreText = styled.span`
  color: rgba(23, 28, 51, 0.5);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const CompanyDetailsPeopleMoreHighlight = styled.span`
  color: rgba(255, 194, 41, 1);
`;
export const CompanyDetailsPeopleMoreButton = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  width: fit-content;
  height: 48px;

  background: #ffc229;
  border-radius: 100px;
  cursor: pointer;

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #171c33;
    user-select: none;
  }
`;

export const CompanyDetailsAtAGlanceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 78px;
  }
`;

export const CompanyDetailsDataHealthBlockContainer = styled.div`
  position: absolute;
  top: -24px;
  right: 48px;

  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const CompanyDetailsDataHealthBlockContent = styled.div`
  background-color: #ffffff;
  width: 300px;
  height: 204px;

  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  position: relative;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const CompanyDetailsDataHealthBlockTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  position: absolute;
  top: 0;
  width: 100%;
  height: 32px;
  border-radius: 8px;
`;

export const CompanyDetailsDataHealthBlockTopContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 12px;
  color: rgba(23, 28, 51, 0.5);
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CompanyDetailsDataHealthBlockVisibleToYou = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CompanyDetailsDataHealthBlockMoreViewers = styled.div`
  font-size: 14px;
  color: #5478a1;
  cursor: pointer;
`;

export const CompanyDetailsDataHealthMainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 40px 16px 16px 16px;
`;

export const CompanyDetailsDataHealthStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const CompanyDetailsDataHealthDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid rgba(23, 28, 51, 0.1);
  padding: 10px 8px 8px 8px;
  margin-top: 8px;
`;

export const CompanyDetailsDataHealthDateTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgba(23, 28, 51, 0.5);
  font-size: 12px;
  letter-spacing: 1.2px;
`;

export const CompanyDetailsDataHealthDateValue = styled.div`
  color: var(--color-text);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
`;

export const CompanyDetailsDataHealthReportCTA = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(84, 120, 161, 0.3);
  border-radius: 8px;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`;

export const DataHealthIconFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #5478a1;
`;

interface DataHealthStatusColorProps {
  color: string;
}

export const DataHealthStatusPillContainer = styled.div<DataHealthStatusColorProps>`
  height: 24px;
  padding: 0 8px 0px 4px;
  border-radius: 8px;
  margin-left: 16px;
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DataHealthStatusPillText = styled.span<DataHealthStatusColorProps>`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const DataHealthStatusCircleContainer = styled.div<DataHealthStatusColorProps>`
  background-color: ${(props) => props.color};
  border-radius: 100%;
  padding: 4px;
  margin-right: 8px;
`;

export const DataHealthStatusCircle = styled.div<DataHealthStatusColorProps>`
  background-color: ${(props) => props.color};
  border-radius: 100%;
  width: 8px;
  height: 8px;
`;

export const DataHealthDrawerWrapper = styled.div<ProfileEditorDrawerWrapperProps>`
  ${slideFromRightAnimation}
  position: fixed;
  top: 0px;
  right: 0px;

  height: 100%;
  min-height: 100vh;
  width: 522px;
  max-width: 522px;
  min-height: 522px;
  z-index: 99999999;

  background: #ffffff;

  ${({ triggerClosing }) => (triggerClosing ? slideToRightAnimation : ``)}

  ${BREAKPOINTS.tablet} {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
`;

export const DataHealthDrawerHeaderContainer = styled.div`
  position: relative;
  padding: 54px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  ${BREAKPOINTS.tablet} {
    padding: 32px;
  }
`;

export const DataHealthDrawerOverTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  color: rgba(23, 28, 51, 0.5);
  margin-bottom: 8px;
`;

export const DataHealthDrawerTitle = styled.div`
  font-size: 26px;
  margin-bottom: 16px;
`;

export const DataHealthDrawerDescription = styled.div`
  font-size: 14px;
  color: rgba(23, 28, 51, 0.5);
`;

export const DataHealthDrawerIconContainer = styled.div`
  position: absolute;
  right: 64px;
  top: 62px;
`;

export const DataHealthDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
`;

export const DataHealthDrawerContentTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: var(--color-text);
  margin-bottom: 16px;
`;

export const DataHealthDrawerForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const DataHealthDrawerEmailInputWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

export const DataHealthDrawerEmailIconContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 10px;
`;

export const DataHealthDrawerEmailInput = styled.input`
  display: flex;
  height: 56px;
  padding: 15px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(173, 173, 173, 0.08);
  width: 100%;
`;

export const DataHealthDrawerFooter = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

interface DataHealthDrawerSubmitButtonProps {
  disabled: boolean;
}

export const DataHealthDrawerSubmitButton = styled.button`
  display: flex;
  height: 52px;
  padding: 16px 48px;
  background-color: #9ccaff;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 14px;
  margin-bottom: 20px;

  ${(props: DataHealthDrawerSubmitButtonProps) =>
    props.disabled
      ? `
    opacity: 0.5;
    cursor: not-allowed;
  `
      : `
    opacity: 1;
    cursor: pointer;
  `}
`;

export const DataHealthDrawerSubmitSpinner = styled.div`
  margin-left: 5px;
  ${infiniteRotationAnimation()}
`;
