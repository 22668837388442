import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ProgressBar from 'app/components/CommonStyled/ProgressBar';
import Search from 'app/components/CommonStyled/Search';
import { CloseIcon, CloseIconSmall, HamburgerIcon, KyuLogoIcon } from 'app/components/shared/icons';
import { AnimatePresence } from 'framer-motion';
import useOnClickOutside from 'hooks/click-outside';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useUserData from 'hooks/useUserData';
import { updateNavOnboarded } from 'services/user';
import {
  isNavBarOpenSelector,
  isNavMenuOpenSelector,
  isSpotlightOpenSelector,
  isUserDropdownOpenSelector,
  setIsNavbarMenuOpen,
  setIsNavBarOpen,
} from 'store/reducers/navbar-reducer';
import { setUserData } from 'store/reducers/user-reducer';
import { ThemeProvider } from 'styled-components';

import NavSections, { NavSectionsOnboardingCalloutClose } from '../NavSections';
import NavbarSpotlight from '../NavbarSpotlight';
import NavbarUserDropdown from '../NavbarUserDropdown';
import {
  NavSectionsOnboardingCalloutCloseContainer,
  NavSectionsOnboardingCalloutContainer,
  NavSectionsOnboardingCalloutText,
  NavSectionsOnboardingCalloutWrapper,
  NavSectionsOnboardingWrapper,
} from '../styled';
import {
  NavbarDesktopContainer,
  NavbarDesktopMenuContainer,
  NavbarDesktopMenuTitleContainer,
  NavbarDesktopLeftSectionWrapper,
  NavbarDesktopWrapper,
  NavbarDesktopRightSectionWrapper,
  NavbarDesktopOpenMenuWrapper,
  NavbarDesktopOpenMenuContainer,
  NavbarDesktopSpotlightWrapper,
  NavbarDesktopSpotlightContainer,
  NavbarDesktopUserDropdownWrapper,
  NavbarDesktopUserDropdownContainer,
  NavbarDesktopOverall,
  NavbarDesktopFiller,
  NavbarToggler,
  NavbarTogglerText,
} from './styled';

export default function NavbarDesktop() {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const navHomepageOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.NAV_HOMEPAGE_OPENED);
  const navSearchOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.NAV_SEARCH_OPENED);
  const isNavbarOpen = useSelector(isNavBarOpenSelector);
  const isMenuOpen = useSelector(isNavMenuOpenSelector);
  const isSpotlightOpen = useSelector(isSpotlightOpenSelector);
  const isUserDropdownOpen = useSelector(isUserDropdownOpenSelector);
  const openMenuRef = useRef<HTMLDivElement>(null);
  const userData = useUserData();

  const isTogglableNavbar = useMemo(() => {
    const isMapPage =
      location.pathname.endsWith('/map') ||
      location.pathname.startsWith('/network-map') ||
      location.pathname.startsWith('/collective');
    const isToolPage = location.pathname.endsWith('/embed');

    return isMapPage || isToolPage;
  }, [location.pathname]);

  useOnClickOutside(openMenuRef, (e) => {
    if (!isMenuOpen) return;
    if (e.target instanceof HTMLElement && e.target.id === 'nav-menu-cta') return;

    const sideDrawer = document.querySelector('[data-type="sidedrawer"]');
    if (sideDrawer) return;
    if (userData.nav_onboarding) {
      dispatch(setUserData({ ...userData, nav_onboarding: false }));
    }
    dispatch(setIsNavbarMenuOpen(false));
  });

  const handleSearchOpen = () => {
    navSearchOpenedBehavioralFunction();
  };

  const handleKyuLogoClick = () => {
    navHomepageOpenedBehavioralFunction();
    push('/');
  };

  const handleHamburgerClick = () => {
    if (userData.nav_onboarding) {
      handleOnboardingClose();
    }
    dispatch(setIsNavbarMenuOpen(!isMenuOpen));
  };

  const handleOnboardingClose = () => {
    dispatch(setUserData({ ...userData, nav_onboarding: false }));
    updateNavOnboarded({ status: false });
  };

  const handleTogglerClick = () => {
    dispatch(setIsNavBarOpen(!isNavbarOpen));
  };

  return (
    <ThemeProvider theme={{ isSpotlightOpen, isNavbarOpen }}>
      <NavbarDesktopOverall>
        <NavbarDesktopFiller />
        <NavbarDesktopWrapper>
          {userData.nav_onboarding ? (
            <NavSectionsOnboardingCalloutWrapper>
              <NavSectionsOnboardingCalloutContainer>
                <NavSectionsOnboardingCalloutText>
                  Give yourself a quick tour around kyu OS.
                </NavSectionsOnboardingCalloutText>
              </NavSectionsOnboardingCalloutContainer>
              <NavSectionsOnboardingCalloutCloseContainer onClick={handleOnboardingClose}>
                <NavSectionsOnboardingCalloutClose />
              </NavSectionsOnboardingCalloutCloseContainer>
            </NavSectionsOnboardingCalloutWrapper>
          ) : null}
          <NavbarDesktopContainer>
            <NavbarDesktopLeftSectionWrapper>
              <NavbarDesktopMenuContainer onClick={handleHamburgerClick} id="nav-menu-cta">
                {userData.nav_onboarding ? <NavSectionsOnboardingWrapper /> : null}
                {!isMenuOpen ? (
                  <HamburgerIcon />
                ) : (
                  <CloseIcon width={13} height={13} fill="var(--color-primary)" fillOpacity="1" />
                )}
              </NavbarDesktopMenuContainer>
              <NavbarDesktopMenuTitleContainer onClick={handleKyuLogoClick}>
                <KyuLogoIcon width={60} height={20} />
              </NavbarDesktopMenuTitleContainer>
              <NavbarSpotlight.CTA />
            </NavbarDesktopLeftSectionWrapper>
            <NavbarDesktopRightSectionWrapper>
              <Search onOpen={handleSearchOpen} />
              <ProgressBar />
              <NavbarUserDropdown.CTA />
            </NavbarDesktopRightSectionWrapper>
            <NavbarDesktopOpenMenuWrapper ref={openMenuRef}>
              <AnimatePresence>
                {isMenuOpen ? (
                  <NavbarDesktopOpenMenuContainer>
                    <NavSections />
                  </NavbarDesktopOpenMenuContainer>
                ) : null}
              </AnimatePresence>
            </NavbarDesktopOpenMenuWrapper>
            <NavbarDesktopSpotlightWrapper>
              <AnimatePresence>
                {isSpotlightOpen ? (
                  <NavbarDesktopSpotlightContainer>
                    <NavbarSpotlight />
                  </NavbarDesktopSpotlightContainer>
                ) : null}
              </AnimatePresence>
            </NavbarDesktopSpotlightWrapper>
            <NavbarDesktopUserDropdownWrapper>
              <AnimatePresence>
                {isUserDropdownOpen ? (
                  <NavbarDesktopUserDropdownContainer>
                    <NavbarUserDropdown />
                  </NavbarDesktopUserDropdownContainer>
                ) : null}
              </AnimatePresence>
            </NavbarDesktopUserDropdownWrapper>
          </NavbarDesktopContainer>
        </NavbarDesktopWrapper>
        <NavbarDesktopFiller />
      </NavbarDesktopOverall>
      {isTogglableNavbar ? (
        <NavbarToggler onClick={handleTogglerClick}>
          {isNavbarOpen ? (
            <>
              <NavbarTogglerText>Close</NavbarTogglerText>
              <CloseIcon width={11} height={11} fillOpacity="1" fill="var(--color-primary)" />
            </>
          ) : (
            <>
              <NavbarTogglerText>Menu</NavbarTogglerText>
              <HamburgerIcon width={16} height={16} />
            </>
          )}
        </NavbarToggler>
      ) : null}
    </ThemeProvider>
  );
}
