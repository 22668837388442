import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSearchbar: true,
  found: null,
  notFound: null,
  prompt: null,
  prompt_uuid: null,
  feedback: null,
  isLoading: false,
};

export const slice = createSlice({
  name: 'nlp',
  initialState,
  reducers: {
    setShowSearchbar: (state, action) => {
      state.showSearchbar = action.payload;
    },
    setFound: (state, action) => {
      state.found = action.payload;
    },
    setNotFound: (state, action) => {
      state.notFound = action.payload;
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    setPromptUuid: (state, action) => {
      state.prompt_uuid = action.payload;
    },
    setFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    setIsNLPLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setShowSearchbar,
  setFound,
  setNotFound,
  setPrompt,
  setPromptUuid,
  setFeedback,
  setIsNLPLoading,
} = slice.actions;

export const showSearchbarSelector = (state) => state.nlp.showSearchbar;
export const foundSelector = (state) => state.nlp.found;
export const notFoundSelector = (state) => state.nlp.notFound;
export const promptSelector = (state) => state.nlp.prompt;
export const promptUuidSelector = (state) => state.nlp.prompt_uuid;
export const feedbackSelector = (state) => state.nlp.feedback;
export const isNLPLoadingSelector = (state) => state.nlp.isLoading;

export default slice.reducer;
