import React from 'react';

export default function ResourceSubmissionFourIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.75" cy="25.0005" r="10.9" stroke="black" strokeWidth="1.2" />
      <path
        d="M51.65 25.0005C51.65 30.9966 46.5704 35.9005 40.25 35.9005C33.9296 35.9005 28.85 30.9966 28.85 25.0005C28.85 19.0044 33.9296 14.1005 40.25 14.1005C46.5704 14.1005 51.65 19.0044 51.65 25.0005Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <circle cx="24.75" cy="41.0005" r="10.9" stroke="black" strokeWidth="1.2" />
      <path
        d="M51.65 41.0005C51.65 46.9966 46.5704 51.9005 40.25 51.9005C33.9296 51.9005 28.85 46.9966 28.85 41.0005C28.85 35.0044 33.9296 30.1005 40.25 30.1005C46.5704 30.1005 51.65 35.0044 51.65 41.0005Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.3768 12.6273L12.3768 12.6273C17.7641 7.23824 24.9261 4.27205 32.5454 4.27205C40.1647 4.27205 47.3283 7.23824 52.7157 12.6273L52.7864 12.5566L52.7157 12.6273C58.1031 18.0146 61.0709 25.1783 61.0709 32.7976C61.0709 40.4168 58.1047 47.5805 52.7157 52.9679L52.7864 53.0386L52.7157 52.9679C47.3283 58.3552 40.1647 61.3231 32.5454 61.3231C24.9261 61.3231 17.7625 58.3569 12.3751 52.9679C6.98775 47.5788 4.02157 40.4152 4.02157 32.7959C4.02157 25.1766 6.98775 18.0146 12.3768 12.6273ZM32.5454 3.40049C16.3105 3.40049 3.15 16.561 3.15 32.7959C3.15 49.0308 16.3105 62.1913 32.5454 62.1913C48.7803 62.1913 61.9408 49.0308 61.9408 32.7959C61.9408 16.561 48.7803 3.40049 32.5454 3.40049Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
