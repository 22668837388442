import React from 'react';

export default function ResourceSubmissionFiveIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M53.9844 33H10.9844" stroke="black" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M32.4844 54.501V11.501" stroke="black" strokeWidth="1.2" strokeMiterlimit="10" />
      <path
        d="M47.6859 48.202L17.2812 17.7974"
        stroke="black"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.2812 48.202L47.6859 17.7974"
        stroke="black"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M52.3707 41.1652L12.5938 24.835"
        stroke="black"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M24.3203 52.8883L40.6506 13.1113"
        stroke="black"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M40.7747 52.8386L24.1953 13.1636"
        stroke="black"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.6484 41.2894L52.3235 24.71"
        stroke="black"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.1111 12.6273L12.1112 12.6273C17.4985 7.23824 24.6605 4.27205 32.2798 4.27205C39.899 4.27205 47.0627 7.23824 52.4501 12.6273L52.5208 12.5566L52.4501 12.6273C57.8374 18.0146 60.8053 25.1783 60.8053 32.7976C60.8053 40.4168 57.8391 47.5805 52.4501 52.9679L52.5208 53.0386L52.4501 52.9679C47.0627 58.3552 39.899 61.3231 32.2798 61.3231C24.6605 61.3231 17.4968 58.3569 12.1095 52.9679C6.72213 47.5788 3.75594 40.4152 3.75594 32.7959C3.75594 25.1766 6.72212 18.0146 12.1111 12.6273ZM32.2798 3.40049C16.0448 3.40049 2.88437 16.561 2.88437 32.7959C2.88437 49.0308 16.0448 62.1913 32.2798 62.1913C48.5147 62.1913 61.6752 49.0308 61.6752 32.7959C61.6752 16.561 48.5147 3.40049 32.2798 3.40049Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
