import React from 'react';

export default function ResourceSubmissionEightIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.3844 33.0171C51.3844 43.7458 42.9083 52.4171 32.4844 52.4171C22.0604 52.4171 13.5844 43.7458 13.5844 33.0171C13.5844 22.2884 22.0604 13.6171 32.4844 13.6171C42.9083 13.6171 51.3844 22.2884 51.3844 33.0171Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <circle cx="32.4844" cy="38.5171" r="13.9" stroke="black" strokeWidth="1.2" />
      <path
        d="M40.3844 44.0171C40.3844 48.6891 36.8156 52.4171 32.4844 52.4171C28.1532 52.4171 24.5844 48.6891 24.5844 44.0171C24.5844 39.3451 28.1532 35.6171 32.4844 35.6171C36.8156 35.6171 40.3844 39.3451 40.3844 44.0171Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.1111 13.1439L12.1112 13.1439C17.4985 7.75484 24.6605 4.78866 32.2798 4.78866C39.899 4.78866 47.0627 7.75484 52.4501 13.1439L52.5208 13.0732L52.4501 13.1439C57.8374 18.5312 60.8053 25.6949 60.8053 33.3142C60.8053 40.9334 57.8391 48.0971 52.4501 53.4845L52.5208 53.5552L52.4501 53.4845C47.0627 58.8718 39.899 61.8397 32.2798 61.8397C24.6605 61.8397 17.4968 58.8735 12.1095 53.4845C6.72213 48.0954 3.75594 40.9318 3.75594 33.3125C3.75594 25.6932 6.72212 18.5312 12.1111 13.1439ZM32.2798 3.91709C16.0448 3.91709 2.88437 17.0776 2.88437 33.3125C2.88437 49.5474 16.0448 62.7079 32.2798 62.7079C48.5147 62.7079 61.6752 49.5474 61.6752 33.3125C61.6752 17.0776 48.5147 3.91709 32.2798 3.91709Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
