import React from 'react';

export default function ResourceSubmissionSevenIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.3613" cy="20.8862" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="20.3613" cy="33" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="20.3613" cy="45.1142" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="32.4785" cy="33" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="32.4785" cy="20.8862" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="44.5878" cy="33" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="32.4785" cy="45.1142" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="44.5878" cy="20.8862" r="2.26909" stroke="black" strokeWidth="1.2" />
      <circle cx="44.5878" cy="45.1142" r="2.26909" stroke="black" strokeWidth="1.2" />
      <path
        d="M12.4861 13.1439L12.4862 13.1439C17.8735 7.75484 25.0355 4.78866 32.6548 4.78866C40.274 4.78866 47.4377 7.75484 52.8251 13.1439L52.8958 13.0732L52.8251 13.1439C58.2124 18.5312 61.1803 25.6949 61.1803 33.3142C61.1803 40.9334 58.2141 48.0971 52.8251 53.4845L52.8958 53.5552L52.8251 53.4845C47.4377 58.8718 40.274 61.8397 32.6548 61.8397C25.0355 61.8397 17.8718 58.8735 12.4845 53.4845C7.09713 48.0954 4.13094 40.9318 4.13094 33.3125C4.13094 25.6932 7.09712 18.5312 12.4861 13.1439ZM32.6548 3.91709C16.4198 3.91709 3.25937 17.0776 3.25937 33.3125C3.25937 49.5474 16.4198 62.7079 32.6548 62.7079C48.8897 62.7079 62.0502 49.5474 62.0502 33.3125C62.0502 17.0776 48.8897 3.91709 32.6548 3.91709Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
