import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  BellIcon,
  CloseIcon,
  HamburgerIcon,
  KyuLogoIcon,
  SearchIconSmall,
} from 'app/components/shared/icons';
import { AnimatePresence } from 'framer-motion';
import useOnClickOutside from 'hooks/click-outside';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import {
  setIsSpotlightOpen,
  isNavMenuOpenSelector,
  isSpotlightOpenSelector,
  setIsNavbarMenuOpen,
  setIsDrawerOpen,
} from 'store/reducers/navbar-reducer';
import { ThemeProvider } from 'styled-components';

import MobileMenu from '../MobileMenu/MobileMenu';
import MobileMenuSearch from '../MobileMenu/MobileMenuSearch';
import NavbarSpotlight from '../NavbarSpotlight';
import {
  NavbarMobileBlurBackground,
  NavbarMobileLeftSectionWrapper,
  NavbarMobileMenuContainer,
  NavbarMobileMenuCTAContainer,
  NavbarMobileRightSectionWrapper,
  NavbarMobileSpotlightContainer,
  NavbarMobileWrapper,
} from './styled';

export default function NavbarMobile() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const isMenuOpen = useSelector(isNavMenuOpenSelector);
  const isSpotlightOpen = useSelector(isSpotlightOpenSelector);

  const navHomepageOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.NAV_HOMEPAGE_OPENED);
  const navSearchOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.NAV_SEARCH_OPENED);

  const menuRef = useRef<HTMLDivElement>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setIsDrawerOpen(isMenuOpen));
  }, [isMenuOpen]);

  useOnClickOutside(menuRef, (e) => {
    const button = document.querySelector('#nav-mobile-menu-cta');
    const drawer = document.querySelector('[data-type="sidedrawer"]');
    const mobileSearchMenu = document.getElementById('mobile-search-menu');

    if (mobileSearchMenu && mobileSearchMenu.contains(event.target as Node)) return;
    if (drawer && drawer.contains(e.target as Node)) return;
    if (button && button.contains(e.target as Node)) return;

    dispatch(setIsNavbarMenuOpen(false));
  });

  const handleKyuOsClick = () => {
    navHomepageOpenedBehavioralFunction();
    push('/');
  };

  const handleHamburgerClick = (e) => {
    e.stopPropagation();
    dispatch(setIsNavbarMenuOpen(!isMenuOpen));
  };

  const handleSpotlightClick = () => {
    dispatch(setIsSpotlightOpen(!isSpotlightOpen));
  };

  const handleSearchClick = () => {
    navSearchOpenedBehavioralFunction();
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    dispatch(setIsNavbarMenuOpen(false));
    setShowSearch(false);
  };

  return (
    <ThemeProvider theme={{ isAnythingOpen: isMenuOpen || isSpotlightOpen }}>
      <NavbarMobileWrapper>
        <NavbarMobileLeftSectionWrapper onClick={handleKyuOsClick}>
          <KyuLogoIcon />
        </NavbarMobileLeftSectionWrapper>
        <NavbarMobileRightSectionWrapper>
          <NavbarMobileMenuCTAContainer onClick={handleSearchClick}>
            <SearchIconSmall width={24} height={24} fill="var(--color-primary)" />
          </NavbarMobileMenuCTAContainer>
          <NavbarMobileMenuCTAContainer onClick={handleSpotlightClick} id="nav-spotlight-cta">
            <BellIcon />
          </NavbarMobileMenuCTAContainer>
          <NavbarMobileMenuCTAContainer onClick={handleHamburgerClick} id="nav-mobile-menu-cta">
            {isMenuOpen ? (
              <CloseIcon width={13} height={13} fill="var(--color-primary)" fillOpacity="1" />
            ) : (
              <HamburgerIcon />
            )}
          </NavbarMobileMenuCTAContainer>
        </NavbarMobileRightSectionWrapper>
        {ReactDOM.createPortal(
          <AnimatePresence>
            {isMenuOpen || isSpotlightOpen ? <NavbarMobileBlurBackground /> : null}
          </AnimatePresence>,
          document.querySelector('#root')
        )}
        {ReactDOM.createPortal(
          <AnimatePresence>
            {isMenuOpen ? (
              <NavbarMobileMenuContainer ref={menuRef}>
                <MobileMenu />
              </NavbarMobileMenuContainer>
            ) : null}
          </AnimatePresence>,
          document.querySelector('#root')
        )}
        {ReactDOM.createPortal(
          <AnimatePresence>
            {isSpotlightOpen ? (
              <NavbarMobileSpotlightContainer>
                <NavbarSpotlight />
              </NavbarMobileSpotlightContainer>
            ) : null}
          </AnimatePresence>,
          document.querySelector('#root')
        )}
        {showSearch ? (
          <MobileMenuSearch onClose={handleSearchClose} closeMenu={handleSearchClose} />
        ) : null}
      </NavbarMobileWrapper>
    </ThemeProvider>
  );
}
