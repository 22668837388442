import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import FeedbackDrawer from 'app/components/ContactDrawers/FeedbackDrawer';
import SubmitContentDrawer from 'app/components/ContactDrawers/SubmitContentDrawer';
import { EmailIcon, InfoIcon } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';
import { setIsNavbarMenuOpen } from 'store/reducers/navbar-reducer';

import {
  NavSectionsOpenWaysToGetInvolvedButton,
  NavSectionsOpenWaysToGetInvolvedButtonLabel,
} from '../styled';
import { handleSignOut } from '../utils';
import MobileMenuProfile from './MobileMenuProfile';
import MobileMenuTiles from './MobileMenuTiles';
import {
  MobileMenuProfileSignOutButton,
  MobileMenuWaysToGetInvolvedContainer,
  MobileMenuWaysToGetInvolvedTitle,
  MobileMenuWaysToGetInvolvedWrapper,
  MobileMenuWrapper,
} from './sytled';

export default function MobileMenu() {
  const dispatch = useDispatch();
  const [showReportAnIssue, setShowReportAnIssue] = useState<boolean>(false);
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuWrapperRef, (event) => {
    const mobileSearchMenu = document.getElementById('mobile-search-menu');
    const menuButton = document.getElementById('menu-button');
    const drawer = document.querySelector('[data-type="sidedrawer"]');
    const hamburgerButton = document.querySelector('#nav-mobile-menu-cta');

    if (hamburgerButton && hamburgerButton.contains(event.target as Node)) return;
    if (mobileSearchMenu && mobileSearchMenu.contains(event.target as Node)) return;
    if (menuButton && menuButton.contains(event.target as Node)) return;
    if (drawer && drawer.contains(event.target as Node)) return;

    dispatch(setIsNavbarMenuOpen(false));
  });

  return (
    <>
      <MobileMenuWrapper ref={menuWrapperRef}>
        <MobileMenuProfile />
        <MobileMenuTiles />
        <MobileMenuWaysToGetInvolvedWrapper>
          <MobileMenuWaysToGetInvolvedTitle>QUICK LINKS</MobileMenuWaysToGetInvolvedTitle>
          <MobileMenuWaysToGetInvolvedContainer>
            <NavSectionsOpenWaysToGetInvolvedButton
              onClick={(e) => {
                e.preventDefault();
                setShowReportAnIssue(true);
              }}
            >
              <EmailIcon width={24} height={24} fill="var(--color-primary)" />
              <NavSectionsOpenWaysToGetInvolvedButtonLabel>
                CONTACT KYU
              </NavSectionsOpenWaysToGetInvolvedButtonLabel>
            </NavSectionsOpenWaysToGetInvolvedButton>
            <NavSectionsOpenWaysToGetInvolvedButton
              onClick={() => {
                window.open('https://kyu.com/legal/employee-privacy-notice', '_blank');
              }}
              stroke
            >
              <InfoIcon width={24} height={24} fill="var(--color-primary)" />
              <NavSectionsOpenWaysToGetInvolvedButtonLabel>
                PRIVACY POLICY
              </NavSectionsOpenWaysToGetInvolvedButtonLabel>
            </NavSectionsOpenWaysToGetInvolvedButton>
          </MobileMenuWaysToGetInvolvedContainer>
        </MobileMenuWaysToGetInvolvedWrapper>
        <MobileMenuProfileSignOutButton onClick={handleSignOut}>
          SIGN OUT
        </MobileMenuProfileSignOutButton>
      </MobileMenuWrapper>
      {showReportAnIssue ? (
        <SideDrawer closeDrawer={() => setShowReportAnIssue(false)} withClose>
          <FeedbackDrawer />
        </SideDrawer>
      ) : null}
    </>
  );
}
