import React from 'react';

export default function BestOf2024Icon({ width = 207, height = 207 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 207 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7081_16650)">
        <path
          d="M102.975 202.88C158.203 202.88 202.973 158.109 202.973 102.881C202.973 47.6536 158.203 2.88281 102.975 2.88281C47.7474 2.88281 2.97656 47.6536 2.97656 102.881C2.97656 158.109 47.7474 202.88 102.975 202.88Z"
          fill="#F15A42"
        />
        <g filter="url(#filter0_d_7081_16650)">
          <path
            d="M25.6309 129.294C24.2447 129.846 23.7616 131.083 24.1124 132.285C23.4395 131.215 22.3639 130.767 21.064 131.284C19.4477 131.929 18.953 133.551 19.7468 135.535L21.3861 139.648L31.377 135.662L29.6745 131.394C28.8807 129.409 27.345 128.616 25.6309 129.3V129.294ZM22.1338 136.662L21.6219 135.38C21.2653 134.482 21.5126 133.786 22.2718 133.482C23.0311 133.182 23.6983 133.528 24.0492 134.413L24.5611 135.696L22.1338 136.662ZM26.1601 135.058L25.5849 133.614C25.2571 132.786 25.5159 132.038 26.2579 131.739C27.0862 131.411 27.7821 131.739 28.1272 132.602L28.7024 134.045L26.1601 135.058Z"
            fill="url(#paint0_linear_7081_16650)"
          />
          <path
            d="M25.2224 114.799L23.261 115.179L24.0318 119.194L21.708 119.637L20.9373 115.622L19.0162 115.996L19.7927 120.01L17.3941 120.471L16.6176 116.456L14.6562 116.835L15.8987 123.295L26.4706 121.259L25.2224 114.799Z"
            fill="url(#paint1_linear_7081_16650)"
          />
          <path
            d="M19.4935 105.706L20.2297 103.761C20.6036 102.789 20.943 102.45 21.5987 102.444C22.3522 102.439 22.7893 102.956 22.7951 103.877C22.8066 104.969 22.1164 105.637 20.9487 105.642L20.9717 108.133C23.3818 108.116 24.7852 106.517 24.7622 103.877C24.745 101.495 23.422 99.9365 21.4089 99.9538C19.9652 99.9653 18.9701 100.65 18.4007 102.145L17.7104 103.934C17.2906 104.998 16.9224 105.418 16.3242 105.418C15.68 105.418 15.2601 104.952 15.2544 104.135C15.2486 103.169 15.7951 102.64 16.7959 102.634L16.7729 100.144C14.6965 100.161 13.3735 101.737 13.3908 104.107C13.408 106.442 14.685 107.92 16.5716 107.909C17.9693 107.897 18.9643 107.149 19.4878 105.7L19.4935 105.706Z"
            fill="url(#paint2_linear_7081_16650)"
          />
          <path
            d="M17.0044 89.5547L25.4251 91.0329L25.8507 88.5941L17.43 87.1159L17.9477 84.194L15.7677 83.8086L14.3125 92.0798L16.4925 92.4594L17.0044 89.5547Z"
            fill="url(#paint3_linear_7081_16650)"
          />
          <path
            d="M32.0917 58.1324C29.4056 56.6657 26.0753 57.2811 24.3439 60.4504C22.6126 63.6197 23.8953 66.7544 26.5814 68.2211C29.2675 69.6879 32.5979 69.0724 34.3292 65.9031C36.0605 62.7339 34.7778 59.5934 32.0917 58.1266V58.1324ZM32.3735 64.839C31.3842 66.6451 29.4631 66.8579 27.8238 65.9492C26.1673 65.0634 25.3103 63.3321 26.2996 61.526C27.2831 59.7314 29.1985 59.5186 30.8493 60.4159C32.4943 61.3132 33.3571 63.0445 32.3735 64.839Z"
            fill="url(#paint4_linear_7081_16650)"
          />
          <path
            d="M42.6507 53.1455L39.815 50.7355L42.4609 47.6237L40.9079 46.3008L38.2562 49.4126L36.0475 47.5317L38.6934 44.4199L37.1001 43.0625L32.8438 48.0724L41.0344 55.0436L42.6507 53.1455Z"
            fill="url(#paint5_linear_7081_16650)"
          />
          <path
            d="M60.2148 37.6557L57.684 33.6351L63.2057 35.7691L65.7136 34.1873L59.2485 31.8521L59.9847 25.0879L57.4827 26.6581L57.0283 32.5941L54.4802 28.5505L52.375 29.8792L58.1096 38.9786L60.2148 37.6557Z"
            fill="url(#paint6_linear_7081_16650)"
          />
          <path
            d="M72.9676 30.9144L75.5675 29.9711L76.8904 18.0303L74.302 18.9736L73.8994 23.6383L70.2872 20.4403L67.5781 21.4296L73.4565 26.4165L72.9676 30.9144Z"
            fill="url(#paint7_linear_7081_16650)"
          />
          <path
            d="M91.0046 26.2785C93.6159 25.8874 95.0942 24.2366 94.7261 21.7576L93.6677 14.7173L91.2059 15.0854L92.2412 21.9704C92.4253 23.184 91.8099 23.9778 90.6882 24.1446C89.5781 24.3114 88.7441 23.7362 88.5658 22.5226L87.5305 15.6376L85.0859 16.0057L86.1443 23.046C86.5182 25.525 88.399 26.6696 91.0104 26.2785H91.0046Z"
            fill="url(#paint8_linear_7081_16650)"
          />
          <path
            d="M117.268 26.8595C120.362 27.452 122.686 26.1865 123.445 23.7478L120.851 23.2531C120.351 24.392 119.212 24.9614 117.705 24.6738C115.715 24.2942 114.817 22.7469 115.214 20.682C115.605 18.6114 117.009 17.5012 118.999 17.8809C120.506 18.1685 121.363 19.1118 121.409 20.3599L124.003 20.8546C124.193 18.3065 122.496 16.2819 119.402 15.6894C115.927 15.0279 113.282 16.9491 112.66 20.1931C112.045 23.4372 113.799 26.1981 117.273 26.8595H117.268Z"
            fill="url(#paint9_linear_7081_16650)"
          />
          <path
            d="M134.054 32.0933C137.361 33.5485 140.375 32.007 141.606 29.2059C142.842 26.4047 141.945 23.1377 138.638 21.6824C135.331 20.2272 132.317 21.7687 131.086 24.5699C129.849 27.371 130.746 30.6381 134.054 32.0933ZM133.461 25.6167C134.203 23.8912 135.854 22.8903 137.741 23.7243C139.61 24.5469 139.984 26.445 139.23 28.159C138.477 29.8731 136.82 30.8797 134.951 30.0514C133.064 29.2231 132.69 27.325 133.461 25.6167Z"
            fill="url(#paint10_linear_7081_16650)"
          />
          <path
            d="M152.014 39.8123L148.758 37.5L153.711 30.5345L151.68 29.0908L145.445 37.8567L150.731 41.6184L152.014 39.8123Z"
            fill="url(#paint11_linear_7081_16650)"
          />
          <path
            d="M162.386 49.4812L159.59 46.6283L165.699 40.6464L163.956 38.8633L156.266 46.3982L160.804 51.0285L162.386 49.4812Z"
            fill="url(#paint12_linear_7081_16650)"
          />
          <path
            d="M172.056 55.2332L174.08 53.8643L176.37 57.2464L178.026 56.1247L174.334 50.6777L165.43 56.7172L169.122 62.1642L170.773 61.0426L168.478 57.6547L170.44 56.3261L172.735 59.7139L174.351 58.6153L172.056 55.2332Z"
            fill="url(#paint13_linear_7081_16650)"
          />
          <path
            d="M180.562 77.0846L179.549 74.6458C178.324 74.8587 177.226 74.2145 176.633 72.7937C175.857 70.9186 176.656 69.3196 178.6 68.5144C180.545 67.7091 182.241 68.267 183.018 70.1364C183.61 71.5571 183.288 72.788 182.276 73.507L183.288 75.9458C185.52 74.7034 186.279 72.1725 185.071 69.2621C183.714 65.9951 180.654 64.8389 177.6 66.1101C174.551 67.3812 173.211 70.3665 174.568 73.6278C175.776 76.5382 178.111 77.7806 180.562 77.0789V77.0846Z"
            fill="url(#paint14_linear_7081_16650)"
          />
          <path
            d="M179.078 87.1272L179.556 89.5544L187.942 87.9209L188.511 90.8371L190.68 90.4115L189.081 82.1748L186.906 82.5947L187.47 85.4936L179.078 87.1272Z"
            fill="url(#paint15_linear_7081_16650)"
          />
          <path
            d="M191.406 98.9252L180.656 99.2969L180.742 101.786L191.492 101.414L191.406 98.9252Z"
            fill="url(#paint16_linear_7081_16650)"
          />
          <path
            d="M179.914 114.667L190.118 119.643L190.463 116.991L183.221 113.793L191.044 112.573L191.389 109.922L180.253 112.108L179.914 114.667Z"
            fill="url(#paint17_linear_7081_16650)"
          />
          <path
            d="M175.758 130.945L177.656 131.572L178.939 127.683L181.188 128.425L179.905 132.308L181.757 132.923L183.04 129.035L185.358 129.8L184.081 133.683L185.979 134.31L188.038 128.063L177.817 124.698L175.758 130.945Z"
            fill="url(#paint18_linear_7081_16650)"
          />
          <path
            d="M102.604 2.92578C47.1736 2.92578 2.23438 47.8593 2.23438 103.29C2.23438 158.721 47.1679 203.654 102.599 203.654C158.029 203.654 202.963 158.721 202.963 103.29C202.963 47.8593 158.035 2.92578 102.604 2.92578ZM171.949 172.634C153.428 191.155 128.798 201.359 102.604 201.359C76.4103 201.359 51.7809 191.161 33.2599 172.634C14.7389 154.113 4.53512 129.484 4.53512 103.29C4.53512 77.096 14.7389 52.4723 33.2599 33.9513C51.7809 15.4246 76.4103 5.22652 102.604 5.22652C128.798 5.22652 153.428 15.4246 171.949 33.9513C190.47 52.4723 200.673 77.1017 200.673 103.296C200.673 129.49 190.475 154.119 171.949 172.64V172.634Z"
            fill="url(#paint19_linear_7081_16650)"
          />
          <path
            d="M47.2891 128.673C47.2891 144.973 60.5011 158.185 76.8018 158.185C87.9029 158.185 97.5718 152.054 102.605 142.989C107.643 152.048 117.306 158.185 128.407 158.185C144.708 158.185 157.92 144.973 157.92 128.673C157.92 117.871 152.117 108.432 143.46 103.29C152.117 98.1476 157.92 88.7088 157.92 77.9068C157.92 61.6061 144.708 48.394 128.407 48.394C117.306 48.394 107.638 54.5255 102.605 63.5904C97.566 54.5313 87.9029 48.394 76.8018 48.394C60.5011 48.394 47.2891 61.6061 47.2891 77.9068C47.2891 88.7088 53.0927 98.1476 61.7492 103.29C53.0927 108.432 47.2891 117.871 47.2891 128.673ZM62.9226 105.268L66.2529 103.29L62.9226 101.311C54.6975 96.4221 49.5898 87.4549 49.5898 77.9068C49.5898 62.906 61.7952 50.6948 76.8018 50.6948C86.672 50.6948 95.7887 56.0613 100.597 64.7063L102.61 68.3242L104.624 64.7063C109.426 56.0613 118.549 50.6948 128.419 50.6948C143.42 50.6948 155.631 62.9002 155.631 77.9068C155.631 87.4549 150.523 96.4221 142.298 101.311L138.968 103.29L142.298 105.268C150.523 110.157 155.631 119.125 155.631 128.673C155.631 143.674 143.426 155.885 128.419 155.885C118.549 155.885 109.426 150.513 104.624 141.873L102.61 138.255L100.597 141.873C95.7945 150.518 86.672 155.885 76.8018 155.885C61.801 155.885 49.5898 143.679 49.5898 128.673C49.5898 119.125 54.6975 110.157 62.9226 105.268Z"
            fill="url(#paint20_linear_7081_16650)"
          />
          <path
            d="M90.6663 105.269C94.8133 107.73 98.2415 111.262 100.588 115.478L102.601 119.096L104.615 115.478C106.956 111.262 110.389 107.736 114.536 105.269L117.867 103.29L114.536 101.311C110.389 98.8496 106.961 95.3179 104.615 91.1018L102.601 87.4839L100.588 91.1018C98.2472 95.3179 94.8133 98.8438 90.6663 101.311L87.3359 103.29L90.6663 105.269ZM102.596 92.2234C105.138 96.7961 108.859 100.621 113.352 103.29C108.859 105.959 105.138 109.784 102.596 114.357C100.053 109.784 96.3318 105.959 91.8396 103.29C96.3318 100.621 100.053 96.7961 102.596 92.2234Z"
            fill="url(#paint21_linear_7081_16650)"
          />
          <path
            d="M128.645 94.5702C138.716 94.5702 146.907 86.3795 146.907 76.308C146.907 66.2365 138.716 58.0459 128.645 58.0459C118.573 58.0459 110.383 66.2365 110.383 76.308C110.383 86.3795 118.573 94.5702 128.645 94.5702ZM128.645 60.3466C137.445 60.3466 144.606 67.5077 144.606 76.308C144.606 85.1084 137.445 92.2694 128.645 92.2694C119.845 92.2694 112.684 85.1084 112.684 76.308C112.684 67.5077 119.845 60.3466 128.645 60.3466Z"
            fill="url(#paint22_linear_7081_16650)"
          />
          <path
            d="M89.7166 92.2696H72.0412C75.4693 89.5548 81.434 84.729 85.0231 81.2433C87.3354 78.9311 88.6123 75.8538 88.6123 72.581C88.6123 65.8284 83.1193 60.3296 76.3608 60.3296C69.6024 60.3296 64.1094 65.8226 64.1094 72.581H66.4101C66.4101 67.0938 70.8736 62.6303 76.3608 62.6303C81.8481 62.6303 86.3115 67.0938 86.3115 72.581C86.3115 75.2384 85.2762 77.7347 83.4126 79.6041C78.3682 84.4989 68.1127 92.4307 68.0091 92.5055L65.3403 94.5646H89.7166V92.2639V92.2696Z"
            fill="url(#paint23_linear_7081_16650)"
          />
          <path
            d="M76.3608 113.58C81.8481 113.58 86.3115 118.043 86.3115 123.531C86.3115 126.188 85.2762 128.684 83.4126 130.554C78.3682 135.448 68.1127 143.38 68.0091 143.455L65.3403 145.514H89.7166V143.213H72.0412C75.4693 140.499 81.434 135.673 85.0231 132.187C87.3354 129.875 88.6123 126.798 88.6123 123.525C88.6123 116.772 83.1193 111.273 76.3608 111.273C69.6024 111.273 64.1094 116.766 64.1094 123.525H66.4101C66.4101 118.038 70.8736 113.574 76.3608 113.574V113.58Z"
            fill="url(#paint24_linear_7081_16650)"
          />
          <path
            d="M134.886 109.876L114.18 135.265H132.586V144.37H134.886V135.265H140.017V132.964H134.886V109.876ZM132.586 132.964H119.029L132.586 116.341V132.964Z"
            fill="url(#paint25_linear_7081_16650)"
          />
          <path
            d="M102.601 184.702C75.9646 184.702 50.967 171.628 35.7304 149.736L33.8438 151.053C49.5118 173.566 75.2111 187.008 102.601 187.008C129.992 187.008 156.404 173.21 172.015 150.104L170.111 148.816C154.926 171.294 129.693 184.708 102.601 184.708V184.702Z"
            fill="url(#paint26_linear_7081_16650)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7081_16650"
          x="1.1372"
          y="1.82861"
          width="203.652"
          height="203.654"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.365724" dy="0.365724" />
          <feGaussianBlur stdDeviation="0.731449" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.137255 0 0 0 0 0.121569 0 0 0 0 0.12549 0 0 0 0.73 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7081_16650" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7081_16650"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7081_16650"
          x1="149.929"
          y1="9.59241"
          x2="13.7592"
          y2="145.762"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7081_16650"
          x1="139.972"
          y1="-0.364388"
          x2="3.80244"
          y2="135.805"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7081_16650"
          x1="131.747"
          y1="-8.58953"
          x2="-4.42271"
          y2="127.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7081_16650"
          x1="124.173"
          y1="-16.1587"
          x2="-11.9966"
          y2="120.011"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7081_16650"
          x1="116.425"
          y1="-23.9121"
          x2="-19.744"
          y2="112.257"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7081_16650"
          x1="114.227"
          y1="-26.1036"
          x2="-21.9427"
          y2="110.066"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7081_16650"
          x1="115.709"
          y1="-24.6312"
          x2="-20.4607"
          y2="111.538"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7081_16650"
          x1="118.804"
          y1="-21.531"
          x2="-17.3653"
          y2="114.638"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_7081_16650"
          x1="125.136"
          y1="-15.1924"
          x2="-11.0333"
          y2="120.977"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_7081_16650"
          x1="139.987"
          y1="-0.352514"
          x2="3.81802"
          y2="135.817"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_7081_16650"
          x1="151.787"
          y1="11.4499"
          x2="15.6171"
          y2="147.619"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_7081_16650"
          x1="163.449"
          y1="23.1146"
          x2="27.2792"
          y2="159.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_7081_16650"
          x1="173.797"
          y1="33.468"
          x2="37.6278"
          y2="169.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_7081_16650"
          x1="184.25"
          y1="43.9021"
          x2="48.0804"
          y2="180.071"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_7081_16650"
          x1="195.798"
          y1="55.4634"
          x2="59.629"
          y2="191.633"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_7081_16650"
          x1="206.992"
          y1="66.6563"
          x2="70.8224"
          y2="202.826"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_7081_16650"
          x1="213.384"
          y1="73.0473"
          x2="77.2143"
          y2="209.217"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_7081_16650"
          x1="220.695"
          y1="80.3631"
          x2="84.5254"
          y2="216.533"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_7081_16650"
          x1="225.868"
          y1="85.5339"
          x2="89.6986"
          y2="221.703"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_7081_16650"
          x1="173.116"
          y1="32.7779"
          x2="36.9468"
          y2="168.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_7081_16650"
          x1="173.117"
          y1="32.7778"
          x2="36.9472"
          y2="168.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_7081_16650"
          x1="173.113"
          y1="32.778"
          x2="36.9439"
          y2="168.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_7081_16650"
          x1="172.641"
          y1="32.3121"
          x2="36.4714"
          y2="168.481"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_7081_16650"
          x1="149.145"
          y1="8.81595"
          x2="12.9753"
          y2="144.985"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_7081_16650"
          x1="174.62"
          y1="34.2906"
          x2="38.4503"
          y2="170.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_7081_16650"
          x1="201.171"
          y1="60.8356"
          x2="65.0013"
          y2="197.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_7081_16650"
          x1="197.461"
          y1="57.1256"
          x2="61.2916"
          y2="193.295"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="white" />
          <stop offset="0.22" stopColor="#F7F6F6" />
          <stop offset="0.24" stopColor="#E1E0E0" />
          <stop offset="0.26" stopColor="#BDBCBC" />
          <stop offset="0.29" stopColor="#8B898A" />
          <stop offset="0.31" stopColor="#4D4A4A" />
          <stop offset="0.33" stopColor="#231F20" />
        </linearGradient>
        <clipPath id="clip0_7081_16650">
          <rect width="207" height="207" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
