import React from 'react';

export default function ResourceSubmissionTwoIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.9297 11.9829V11.9829C33.1454 23.4464 42.4675 32.7071 53.9297 32.9829V32.9829V32.9829C42.4675 33.2587 33.1454 42.5195 32.9297 53.9829V53.9829V53.9829C32.5616 42.5712 23.3439 33.2613 11.9297 32.9829V32.9829V32.9829C23.3439 32.7045 32.5616 23.3946 32.9297 11.9829V11.9829Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M32.9297 26.9829V26.9829C32.9913 30.2582 35.6548 32.9041 38.9297 32.9829V32.9829V32.9829C35.6548 33.0617 32.9913 35.7076 32.9297 38.9829V38.9829V38.9829C32.8245 35.7224 30.1909 33.0625 26.9297 32.9829V32.9829V32.9829C30.1909 32.9034 32.8245 30.2434 32.9297 26.9829V26.9829Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M12.0565 13.1097L12.0565 13.1097C17.4438 7.72066 24.6058 4.75448 32.2251 4.75448C39.8444 4.75448 47.008 7.72066 52.3954 13.1097L52.4661 13.039L52.3954 13.1097C57.7827 18.4971 60.7506 25.6607 60.7506 33.28C60.7506 40.8993 57.7844 48.0629 52.3954 53.4503L52.4661 53.521L52.3954 53.4503C47.008 58.8376 39.8443 61.8055 32.2251 61.8055C24.6058 61.8055 17.4421 58.8393 12.0548 53.4503C6.66744 48.0613 3.70125 40.8976 3.70125 33.2783C3.70125 25.659 6.66744 18.4971 12.0565 13.1097ZM32.2251 3.88291C15.9901 3.88291 2.82969 17.0434 2.82969 33.2783C2.82969 49.5133 15.9901 62.6737 32.2251 62.6737C48.46 62.6737 61.6205 49.5133 61.6205 33.2783C61.6205 17.0434 48.46 3.88291 32.2251 3.88291Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
