import React from 'react';

import {
  BestResourceIcon,
  BestResourceRibbonIcon,
  ResourceSubmissionEightHonoreeIcon,
  ResourceSubmissionEightIcon,
  ResourceSubmissionEightWinnerIcon,
  ResourceSubmissionFiveHonoreeIcon,
  ResourceSubmissionFiveIcon,
  ResourceSubmissionFiveWinnerIcon,
  ResourceSubmissionFourHonoreeIcon,
  ResourceSubmissionFourIcon,
  ResourceSubmissionFourWinnerIcon,
  ResourceSubmissionNineHonoreeIcon,
  ResourceSubmissionNineIcon,
  ResourceSubmissionNineWinnerIcon,
  ResourceSubmissionOneHonoreeIcon,
  ResourceSubmissionOneIcon,
  ResourceSubmissionOneWinnerIcon,
  ResourceSubmissionSevenHonoreeIcon,
  ResourceSubmissionSevenIcon,
  ResourceSubmissionSevenWinnerIcon,
  ResourceSubmissionSixHonoreeIcon,
  ResourceSubmissionSixIcon,
  ResourceSubmissionSixWinnerIcon,
  ResourceSubmissionThreeHonoreeIcon,
  ResourceSubmissionThreeIcon,
  ResourceSubmissionThreeWinnerIcon,
  ResourceSubmissionTwoHonoreeIcon,
  ResourceSubmissionTwoIcon,
  ResourceSubmissionTwoWinnerIcon,
  ResourceSubmissionOneNomineeIcon,
  ResourceSubmissionTwoNomineeIcon,
  ResourceSubmissionThreeNomineeIcon,
  ResourceSubmissionFourNomineeIcon,
  ResourceSubmissionFiveNomineeIcon,
  ResourceSubmissionSixNomineeIcon,
  ResourceSubmissionSevenNomineeIcon,
  ResourceSubmissionEightNomineeIcon,
  ResourceSubmissionNineNomineeIcon,
} from 'app/components/shared/icons';
import { ResourceVotingCategories } from 'store/reducers/resource-voting/resource-voting-reducer';
import { AllResourceVotingCategories } from 'store/reducers/resource-winners/resource-winners-reducer';

export const resourceSubmissionCategoryToIcon = new Map<AllResourceVotingCategories, JSX.Element>([
  [
    AllResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    <ResourceSubmissionOneIcon />,
  ],
  [AllResourceVotingCategories.AI_AND_EMERGING_TECH, <ResourceSubmissionTwoIcon />],
  [AllResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN, <ResourceSubmissionThreeIcon />],
  [AllResourceVotingCategories.CULTURE_AND_INCLUSION, <ResourceSubmissionFourIcon />],
  [
    AllResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    <ResourceSubmissionFiveIcon />,
  ],
  [AllResourceVotingCategories.MEDIA_AND_ADVERTISING, <ResourceSubmissionSixIcon />],
  [AllResourceVotingCategories.OPERATIONAL_INNOVATION, <ResourceSubmissionSevenIcon />],
  [AllResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT, <ResourceSubmissionEightIcon />],
  [AllResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION, <ResourceSubmissionNineIcon />],
  [AllResourceVotingCategories.WINNERS, <BestResourceRibbonIcon />],
  [AllResourceVotingCategories.POPULAR, <BestResourceIcon />],
]);

export const resourceSubmissionCategoryToWinnerIcon = new Map<
  AllResourceVotingCategories,
  JSX.Element
>([
  [
    AllResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    <ResourceSubmissionOneWinnerIcon />,
  ],
  [AllResourceVotingCategories.AI_AND_EMERGING_TECH, <ResourceSubmissionTwoWinnerIcon />],
  [AllResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN, <ResourceSubmissionThreeWinnerIcon />],
  [AllResourceVotingCategories.CULTURE_AND_INCLUSION, <ResourceSubmissionFourWinnerIcon />],
  [
    AllResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    <ResourceSubmissionFiveWinnerIcon />,
  ],
  [AllResourceVotingCategories.MEDIA_AND_ADVERTISING, <ResourceSubmissionSixWinnerIcon />],
  [AllResourceVotingCategories.OPERATIONAL_INNOVATION, <ResourceSubmissionSevenWinnerIcon />],
  [AllResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT, <ResourceSubmissionEightWinnerIcon />],
  [
    AllResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
    <ResourceSubmissionNineWinnerIcon />,
  ],
]);

export const resourceSubmissionCategoryToHonoreeIcon = new Map<
  AllResourceVotingCategories,
  JSX.Element
>([
  [
    AllResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    <ResourceSubmissionOneHonoreeIcon />,
  ],
  [AllResourceVotingCategories.AI_AND_EMERGING_TECH, <ResourceSubmissionTwoHonoreeIcon />],
  [AllResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN, <ResourceSubmissionThreeHonoreeIcon />],
  [AllResourceVotingCategories.CULTURE_AND_INCLUSION, <ResourceSubmissionFourHonoreeIcon />],
  [
    AllResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    <ResourceSubmissionFiveHonoreeIcon />,
  ],
  [AllResourceVotingCategories.MEDIA_AND_ADVERTISING, <ResourceSubmissionSixHonoreeIcon />],
  [AllResourceVotingCategories.OPERATIONAL_INNOVATION, <ResourceSubmissionSevenHonoreeIcon />],
  [AllResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT, <ResourceSubmissionEightHonoreeIcon />],
  [
    AllResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
    <ResourceSubmissionNineHonoreeIcon />,
  ],
]);

export const resourceSubmissionCategoryToNomineeIcon = new Map<
  AllResourceVotingCategories,
  JSX.Element
>([
  [
    AllResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    <ResourceSubmissionOneNomineeIcon />,
  ],
  [AllResourceVotingCategories.AI_AND_EMERGING_TECH, <ResourceSubmissionTwoNomineeIcon />],
  [AllResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN, <ResourceSubmissionThreeNomineeIcon />],
  [AllResourceVotingCategories.CULTURE_AND_INCLUSION, <ResourceSubmissionFourNomineeIcon />],
  [
    AllResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    <ResourceSubmissionFiveNomineeIcon />,
  ],
  [AllResourceVotingCategories.MEDIA_AND_ADVERTISING, <ResourceSubmissionSixNomineeIcon />],
  [AllResourceVotingCategories.OPERATIONAL_INNOVATION, <ResourceSubmissionSevenNomineeIcon />],
  [AllResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT, <ResourceSubmissionEightNomineeIcon />],
  [
    AllResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
    <ResourceSubmissionNineNomineeIcon />,
  ],
]);

export const resourceSubmissionCategoryToBackground = new Map<ResourceVotingCategories, string>([
  [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES, '#FA7C4A'],
  [ResourceVotingCategories.MEDIA_AND_ADVERTISING, '#D5C4E4'],
  [ResourceVotingCategories.AI_AND_EMERGING_TECH, '#84BEBF'],
  [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN, '#392B624D'],
  [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION, '#F5BC2C'],
  [ResourceVotingCategories.CULTURE_AND_INCLUSION, '#84BEBF'],
  [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY, '#B1D384'],
  [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT, '#E99080'],
  [ResourceVotingCategories.OPERATIONAL_INNOVATION, '#96D7D966'],
]);
