import { ApiResponse } from 'apisauce';
import { ISearchResponse } from 'app/components/CommonStyled/Search/hooks/useSearch';
import {
  ICommunityConveningsParams,
  ICommunityConveningsResponse,
  ICommunityDataResponse,
  ICommunityResourcesParams,
  ICommunityResourcesResponse,
} from 'app/components/Communities/interfaces/community';
import {
  ICompanyDetailsResponse,
  ICompanyGlanceResponse,
  ICompanyImpactResponse,
  ICompanyNewPeopleResponse,
  ICompanyPeopleFiltersResponse,
  ICompanyPeopleResponse,
  ICompanyProjectsResponse,
  ICompanySnapshotResponse,
  ICompanyStoriesAndHighlightsResponse,
} from 'app/components/DetailsPages/interfaces/company';
import { IPersonGlobalDrawerResponse } from 'app/components/DetailsPages/interfaces/person';
import { ICompanies, ICompanyListCompany, IFilters } from 'interfaces';

import { getHttpNoApiWithToken, getHttpWithToken, http, httpNoApi } from './http';
import { getToken } from './storage';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export * from './client';
export * from './collection';
export * from './community';
export * from './industry';
export * from './map';
export * from './passion';
export * from './person';
export * from './profile-editor';
export * from './project';
export * from './discipline';
export * from './skill';
export * from './the-well';
export * from './activity-feed';
export * from './onboarding';
export * from './resources';
export * from './people';
export * from './kyu101';

export const getSearch = ({ term, model }) => {
  return http.get('/answer_options', { term, model });
};

export const getSearchHighlights = () => {
  return http.get('/search/highlights');
};

export const getSearchLenses = () => {
  return http.get('/search/lenses');
};

export const getSearchSingle = ({ term }: { term: string }) => {
  return http.get<Array<ISearchResponse>>('/search', { term });
};

export const getNLPSearch = ( query : { query: string }) => {
  return http.get(`/search/nlp`, { prompt: query } );
};

export const updateNLPFeedback = (feedback, prompt_uuid) => {
  return http.put(`/search/update_nlp_feedback`, { feedback, prompt_uuid });
};

export const getResultsPageData = (uuidsByModel) => {
  return http.get(`/search/multiple${uuidsByModel}`);
};

export const getFavorites = () => {
  return http.get(`/favorite_cards`);
};

export const addFavorite = (card_type, card_uuids) => {
  return http.post(`/favorite_cards`, { card_type, card_uuids });
};

export const deleteFavorite = (card_uuid) => {
  return http.delete(`/favorite_cards`, { card_uuid });
};

export const getGraph = ({
  model,
  uuid,
  slug,
}: {
  model: string;
  uuid?: string;
  slug?: string;
}) => {
  return http.get('/graph', { model, uuid, slug });
};

export const getGraphMultiple = ({ kyu_skills, passions, companies }) => {
  return http.get('/people_passions_skills', {
    kyu_skills,
    passions,
    companies,
  });
};

export const getGraphPeople = ({ uuid, slug }: { uuid?: string; slug?: string[] }) => {
  return http.get('/known_group', { uuid, slug });
};

export const getKyuCompanies = (): Promise<ApiResponse<ICompanies>> => {
  return http.get('/kyu_companies');
};

export const sendFeedback = ({ consent, feedback_text }) => {
  return http.post('/mailer/feedbacks', { consent, feedback_text });
};

export const sendPulseFeedback = ({ subject, message }: { subject: string; message: string }) => {
  return http.post('/mailer/pulse_feedback', { subject, message });
};

export const sendBusinessDevelopmentContact = ({ consent, contact_text }) => {
  return http.post('/mailer/business_development', { consent, contact_text });
};

export const sendCommunitiesContact = ({ first_name, feedback_text }) => {
  return http.post('/mailer/communities', { first_name, feedback_text });
};

export const getProjectsList = ({ map_type, map_ids }) => {
  return http.get('/projects_list', { map_type, map_ids });
};

export const getPeopleList = ({ map_type, map_ids, page, per_page, alumni, sort, filters }) => {
  const props = {
    map_type: map_type,
    map_ids: map_ids,
    alumni: alumni,
    sort: sort,
    location: filters ? filters.location : [],
    kyu_companies: filters ? filters.kyu_companies : [],
    page: page,
    per_page: per_page,
  };

  return http.get('/people_list', props);
};

export const getNodeCardData = ({ map_type, map_ids, node_type, node_id }) => {
  return http.get('/node_cards', { map_type, map_ids, node_type, node_id });
};

export const getFilter = ({ map_type, map_ids, filter_type }) => {
  return http.get('/filters', { map_type, map_ids, filter_type });
};

export const getPrompts = () => {
  return http.get(`/search/random`);
};

export const getUserData = () => {
  return http.get(`/person/profile`);
};

export const updateOnboarded = (onboarded: boolean) => {
  return http.put(`/person/update_onboarded`, { onboarded });
};

export const getOnboardingCounters = () => {
  return http.get(`/onboarding/counters`);
};

export const getPersonDrawerData = (slug: string) => {
  return http.get<IPersonGlobalDrawerResponse>(`/person/${slug}/drawer_data`);
};

export const getKyuSkillDrawerData = (slug: string) => {
  return http.get(`/kyu_skill/${slug}/drawer_data`);
};

export const getSkillDrawerData = (slug: string) => {
  return http.get(`/skill/${slug}/drawer_data`);
};

export const getPassionDrawerData = (slug: string) => {
  return http.get(`/passion/${slug}/drawer_data`);
};

export const getCompanyDrawerData = (slug: string) => {
  return http.get(`/member_company/${slug}/drawer_data`);
};

export const getDisciplineDrawerData = (slug: string) => {
  return http.get(`/discipline/${slug}/drawer_data`);
};

export const getIndustryDrawerData = (slug: string) => {
  return http.get(`/industry/${slug}/drawer_data`);
};

export const getProjectDrawerData = (slug: string) => {
  return http.get(`/project/${slug}/drawer_data`);
};

export const getClientDrawerData = (slug: string) => {
  return http.get<ClientDrawerResponse>(`/client/${slug}/drawer_data`);
};

export const getCollectiveWithKyu = () => {
  return http.get(`/companies/collective_with_kyu`);
};

export const getDrawerData = (model: string, range?: string) => {
  return http.get<ICompanyListCompany[]>(
    `/search/options?model=${model}${range ? `&range=${range}` : ''}`
  );
};

export const getPeopleDropdownsData = () => {
  return http.get(`/search/option_filters?model=people`);
};

export const getCompanyPeople = (slug: string, filters?: IFilters) => {
  return http.get<ICompanyPeopleResponse>(`/member_company/${slug}/details/people`, filters);
};

export const getCompanyPeopleFilters = (slug: string) => {
  return http.get(`/member_company/${slug}/details/people_filters`);
};

// REPORTS
export const getReports = () => {
  return http.get(`/reports`);
};

export const getFinancialReport = () => {
  return http.get(`/reports/financial`);
};

export const getReportsProfileCompletionOverview = (selectedOption) => {
  if (!selectedOption) {
    return http.get(`/reports/profile_completion_overview`);
  }
  const props = {
    report_id: selectedOption,
  };
  return http.get(`/reports/profile_completion_overview`, props);
};

export const getReportsProfileCompletionBreakdown = () => {
  return http.get(`/reports/profile_completion_breakdown`);
};

export const getReportsMasterUsage = () => {
  return http.get(`/reports/master_usage`);
};

export const getReportsTopKeywords = () => {
  return http.get(`/reports/top_keywords`);
};

export const getReportsActions = () => {
  return http.get(`/reports/actions`);
};

export const getReportsUsersOverview = () => {
  return http.get(`/reports/users_overview`);
};

export const getReportsUsersDisciplines = () => {
  return http.get(`/reports/users_disciplines`);
};

export const getReportsUsersLevels = () => {
  return http.get(`/reports/users_levels`);
};

export const getReportsArticles = () => {
  return http.get(`/reports/articles`);
};

export const getReportsCompanyTotals = () => {
  return http.get(`/reports/company_totals`);
};

// COMMUNITIES
export const getCommunitiesIndexData = () => {
  return http.get<ICommunityListResponse>(`/communities`);
};

export const getCommunityData = (slug: string) => {
  return http.get<ICommunityDataResponse>(`/communities/${slug}/details`);
};

export const getCommunityConvenings = (slug: string, params: ICommunityConveningsParams) => {
  return http.get<ICommunityConveningsResponse>(`/communities/${slug}/details/convenings`, params);
};

export const getUpcomingCommunityConvenings = (slug: string) => {
  return http.get<ICommunityConveningsResponse>(`/communities/${slug}/details/upcoming_convenings`);
};

export const getCommunityUpcomingConvenings = (
  slug: string,
  params: ICommunityConveningsParams
) => {
  return http.get<ICommunityConveningsResponse>(
    `/communities/${slug}/details/upcoming_convenings`,
    params
  );
};

export const getCommunityPastConvenings = (slug: string, params: ICommunityConveningsParams) => {
  return http.get<ICommunityConveningsResponse>(
    `/communities/${slug}/details/past_convenings`,
    params
  );
};

export const getCommunityResources = (slug: string, params: ICommunityResourcesParams) => {
  return http.get<ICommunityResourcesResponse>(`/communities/${slug}/details/resources`, params);
};

// KIN
export const getKinGatheringData = (slug: string = 'kin-22-beyond') => {
  return http.get(`/kin_gatherings?slug=${slug}`);
};

export const getKinConveningData = (slug: string) => {
  return http.get(`/convenings/${slug}`);
};

// AUTH

export const requestMagicLink = (email, redirect_to = '/') => {
  return http.post('/send_magic_link', { email, redirect_to });
};

export const loginGoogleService = (user_token) => {
  const dataToSend = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: 'assertion',
    provider: 'google_oauth2',
    assertion: user_token,
  };

  return httpNoApi.post(`/oauth/token`, dataToSend);
};

export const loginMicrosoftService = (user_token) => {
  const dataToSend = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: 'assertion',
    provider: 'microsoft_oauth2',
    assertion: user_token,
  };

  return httpNoApi.post(`/oauth/token`, dataToSend);
};

export const logoutService = () => {
  const dataToSend = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    token: getToken(),
  };

  return httpNoApi.post(`/oauth/revoke`, dataToSend);
};

export const checkTokenStatus = (token: string) => {
  const httpNoApiWithToken = getHttpNoApiWithToken(token);
  return httpNoApiWithToken.get(`/oauth/token/info`);
};

export const updateTosStatus = (token: string) => {
  const httpWithToken = getHttpWithToken(token);
  return httpWithToken.put(`/accept_privacy_notice`);
};

export const checkCrossToken = (token: string, redirect_to?: string) => {
  return http.post(`/cross_apps_login`, { token, redirect_to });
};

export const setSignedIn = () => {
  return http.get(`/person/set_signed_in`);
};

export const getCompanyDetails = (slug: string) => {
  return http.get<ICompanyDetailsResponse>(`/member_company/${slug}/details`);
};

export const getCompanySnapshot = (slug: string) => {
  return http.get<ICompanySnapshotResponse>(`/member_company/${slug}/details/snapshot`);
};

export const getCompanyAtAGlance = (slug: string) => {
  return http.get<ICompanyGlanceResponse>(`/member_company/${slug}/details/at_a_glance`);
};

export const getCompanyImpact = (slug: string) => {
  return http.get<ICompanyImpactResponse>(`/member_company/${slug}/details/impact`);
};

export const getCompanyProjects = (slug: string) => {
  return http.get<ICompanyProjectsResponse>(`/member_company/${slug}/details/projects`);
};

export const getCompanyNewPeople = (slug: string) => {
  return http.get<ICompanyNewPeopleResponse>(`/member_company/${slug}/details/new_people`);
};

export const getCompanyStoriesAndHighlights = (slug: string) => {
  return http.get<ICompanyStoriesAndHighlightsResponse>(
    `/member_company/${slug}/details/stories_and_highlights`
  );
};

export const postDataHealthViewerEmails = (slug: string, viewers_emails: any[] = []) => {
  return http.post(`/member_company/${slug}/drawers/add_data_health_viewers`, { viewers_emails });
};

export const getPeopleFilters = (companySlug: string, filter_slugs_list: string[] = []) => {
  return http.post<ICompanyPeopleFiltersResponse>(
    `/member_company/${companySlug}/drawers/people_filters`,
    { filter_slugs_list }
  );
};

interface PeopleDrawerParams {
  passion_uuids?: string[];
  roles_uuids?: string[];
  locations_uuids?: string[];
  kyu_skills_uuids?: string[];
}

export const getPeopleDrawerList = (companySlug: string, params: PeopleDrawerParams) => {
  return http.get(`/member_company/${companySlug}/drawers/people`, params);
};

export const getSkillsDrawerList = (companySlug: string) => {
  return http.get(`/member_company/${companySlug}/drawers/kyu_skills`);
};

export const getPassionsDrawerList = (companySlug: string) => {
  return http.get(`/member_company/${companySlug}/drawers/passions`);
};

export const getIndustriesDrawerList = (companySlug: string) => {
  return http.get(`/member_company/${companySlug}/drawers/industries`);
};

export const getClientsDrawerList = (companySlug: string) => {
  return http.get(`/member_company/${companySlug}/drawers/clients`);
};

interface ProjectsDrawerParams {
  sort_dir?: string;
  filter_by?: string;
  filter_slugs_list?: string[];
  current_person?: boolean;
  page?: number;
  per_page?: number;
}

export const getProjectsDrawerList = (
  companySlug: string,
  params: ProjectsDrawerParams = {
    sort_dir: 'desc',
    filter_by: '',
    filter_slugs_list: [],
    current_person: false,
    page: 1,
    per_page: 50,
  }
) => {
  return http.get(`/member_company/${companySlug}/drawers/projects`, params);
};
export const sendContentRequest = (content) => {
  return http.post<any>(`/mailer/resource_request`, content);
};

export const getKyuTeam = () => {
  return http.get<any>('/kyu_team');
};

export const getMetaTags = (url: string) => {
  return http.get<any>('/meta_tags', { url });
};

export const getReactions = (slug?: string) => {
  if (slug) {
    return getReactionById();
  } else {
    return getAllReactions();
  }
};

const getAllReactions = () => {
  return new Promise<{ data: any }>((resolve) => {
    setTimeout(() => {
      resolve({
        data: [
          {
            reaction_type: 'like',
            person: {
              slug: 'lucrecia-varea',
              name: 'Lucrecia Varea',
            },
            company: {
              slug: 'gehl',
              name: 'Gehl',
            },
            association: {
              slug: 'what-to-wear-mountaineering',
              name: 'What to Wear Mountaineering',
              type: 'resource',
            },
          },
          {
            reaction_type: 'like',
            person: {
              slug: 'timothy-oberbrunner-predovic',
              name: 'Timothy Oberbrunner Predovic',
            },
            company: {
              slug: 'godfrey-dadich-partners',
              name: 'Godfrey Dadich Partners',
            },
            association: {
              slug: 'enhancing-human-brilliance',
              name: 'Enhancing Human Brilliance',
              type: 'article',
            },
          },
          {
            reaction_type: 'like',
            person: {
              slug: 'cb-medhurst',
              name: 'CB Medhurst',
            },
            company: {
              slug: 'ideo',
              name: 'IDEO',
            },
            association: {
              slug: 'black-history-month-celebration-trivia-event',
              name: 'Black History Month Celebration: Trivia Event',
              type: 'convening',
            },
          },
          {
            reaction_type: 'like',
            person: {
              slug: 'maegan-heaney',
              name: 'Maegan Heaney',
            },
            company: {
              slug: 'ideo',
              name: 'IDEO',
            },
            association: {
              slug: 'the-big-climate-question',
              name: 'The Big Climate Question',
              type: 'article',
            },
          },
          {
            reaction_type: 'like',
            person: {
              slug: 'na-schuppe',
              name: 'Na Schuppe',
            },
            company: {
              slug: 'gehl',
              name: 'Gehl',
            },
            association: {
              slug: 'black-history-month-celebration-podcast-listening-party',
              name: 'Black History Month Celebration: Podcast Listening Party',
              type: 'convening',
            },
          },
          {
            reaction_type: 'like',
            person: {
              slug: 'ronny-roberts',
              name: 'Ronny Roberts',
            },
            company: {
              slug: 'godfrey-dadich-partners',
              name: 'Godfrey Dadich Partners',
            },
            association: {
              slug: 'black-history-month-resource-guide-2024',
              name: 'Black History Month Resource Guide 2024',
              type: 'resource',
            },
          },
          {
            reaction_type: 'like',
            person: {
              slug: 'hiba-ganta',
              name: 'Hiba Ganta',
            },
            company: {
              slug: 'gehl',
              name: 'Gehl',
            },
            association: {
              slug: 'recommended-reads-for-international-women-s-day',
              name: 'Recommended Reads for International Women’s Day',
              type: 'resource',
            },
          },
          {
            reaction_type: 'like',
            person: {
              slug: 'jerome-lynch-dicki',
              name: 'Jerome Lynch',
            },
            company: {
              slug: 'ideo',
              name: 'IDEO',
            },
            association: {
              slug: 'black-history-month-celebration-podcast-listening-party',
              name: 'Black History Month Celebration: Podcast Listening Party',
              type: 'convening',
            },
          },
        ],
      });
    }, 1000);
  });
};

const getReactionById = () => {
  return new Promise<{ data: any }>((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          liked_by_you: true,
          likes: 2,
        },
      });
    }, 1000);
  });
};
