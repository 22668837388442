import { SliceCaseReducers, createSelector, createSlice } from '@reduxjs/toolkit';
import { IResource } from 'app/components/Resources/ResourceCard';

import { ResourceVotingCategories } from '../resource-voting/resource-voting-reducer';

export const AllResourceVotingCategories = {
  ...ResourceVotingCategories,
  WINNERS: 'The Winners',
  POPULAR: 'The Popular',
  MY_COMPANY: 'MY_COMPANY',
} as const;

export type AllResourceVotingCategories =
  (typeof AllResourceVotingCategories)[keyof typeof AllResourceVotingCategories];

export interface ResourceWinnerPerson {
  name: string;
  slug: string;
  uuid: string;
  role: string;
  avatar: string;
  resources_count: number;
  resources: ResourceWinnerPersonResource[];
  company: ResourceWinnerPersonCompany;
}

export interface ResourceWinnerPersonResource {
  name: string;
  slug: string;
  uuid: string;
}

export interface ResourceWinnerPersonCompany {
  name: string;
  slug: string;
  uuid: string;
}

export interface ResourceWinnersState {
  mostVoted: IResource[];
  honorees: {
    [key in ResourceVotingCategories]: IResource[];
  };
  resources: {
    [key in ResourceVotingCategories]: IResource[];
  };
  resourcesByCompany: {
    [key in ResourceVotingCategories]: {
      [key: string]: IResource[];
    };
  };
  ownCompanyResources: {
    [key in ResourceVotingCategories]: IResource[];
  };
  currentCategory: AllResourceVotingCategories;
  information: {
    submissions_count: number;
    people_count: number;
    votes_count: number;
  };
  people: ResourceWinnerPerson[];
  popular: IResource[];
}

export const slice = createSlice<ResourceWinnersState, SliceCaseReducers<ResourceWinnersState>>({
  name: 'resourceWinners',
  initialState: {
    mostVoted: [] as IResource[],
    popular: [] as IResource[],
    honorees: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
    },
    resources: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
    },
    currentCategory: AllResourceVotingCategories.WINNERS,
    resourcesByCompany: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: {},
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: {},
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: {},
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: {},
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: {},
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: {},
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: {},
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: {},
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: {},
    },
    ownCompanyResources: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
    },
    information: null,
    people: [],
  },
  reducers: {
    setMostVoted: (state, action: { payload: IResource[] }) => ({
      ...state,
      mostVoted: action.payload,
    }),
    setPopular: (state, action: { payload: IResource[] }) => ({
      ...state,
      popular: action.payload,
    }),
    setHonorees: (
      state,
      action: {
        payload: {
          [key in ResourceVotingCategories]: IResource[];
        };
      }
    ) => ({
      ...state,
      honorees: action.payload,
    }),
    setCurrentCategory: (state, action: { payload: ResourceVotingCategories }) => ({
      ...state,
      currentCategory: action.payload,
    }),
    setResources: (
      state,
      action: {
        payload: {
          resources: {
            [key in ResourceVotingCategories]: IResource[];
          };
          company: string;
        };
      }
    ) => {
      const resourcesByCompany = {
        [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: {},
        [ResourceVotingCategories.AI_AND_EMERGING_TECH]: {},
        [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: {},
        [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: {},
        [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: {},
        [ResourceVotingCategories.CULTURE_AND_INCLUSION]: {},
        [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: {},
        [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: {},
        [ResourceVotingCategories.OPERATIONAL_INNOVATION]: {},
      };

      let ownCompanyResourcesTemp = {
        [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
        [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
        [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
        [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
        [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
        [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
        [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
        [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
        [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
      };

      Object.keys(action.payload.resources).forEach((category) => {
        const resources: IResource[] = action.payload.resources[category];

        const data = resources.reduce(
          (acc, resource) => {
            resource.contributors.companies.forEach((company) => {
              if (!acc.resources[company.slug]) {
                acc.resources[company.slug] = [];
              }

              if (company.slug === action.payload.company) {
                if (!acc.ownCompanyResources[category]) {
                  acc.ownCompanyResources[category] = [];
                }

                if (!acc.ownCompanyResources[category].find((r) => r.slug === resource.slug)) {
                  acc.ownCompanyResources[category].push(resource);
                }
              }

              acc.resources[company.slug].push(resource);
            });

            resource.contributors.people.forEach((person: any) => {
              if (!acc.resources[person.company_slug]) {
                acc.resources[person.company_slug] = [];
              }

              if (!acc.resources[person.company_slug].find((r) => r.slug === resource.slug)) {
                acc.resources[person.company_slug].push(resource);
              }

              if (person.company_slug === action.payload.company) {
                if (!acc.ownCompanyResources[category]) {
                  acc.ownCompanyResources[category] = [];
                }

                if (
                  acc.ownCompanyResources[category] &&
                  !acc.ownCompanyResources[category].find((r) => r.slug === resource.slug)
                ) {
                  acc.ownCompanyResources[category].push(resource);
                }
              }
            });

            return acc;
          },
          {
            resources: {},
            ownCompanyResources: {},
          }
        );

        resourcesByCompany[category] = data.resources;
        ownCompanyResourcesTemp[category] = data.ownCompanyResources[category] || [];
      });

      return {
        ...state,
        resources: action.payload.resources,
        resourcesByCompany,
        ownCompanyResources: ownCompanyResourcesTemp,
      };
    },
    setInformation: (state, action: { payload: ResourceWinnersState['information'] }) => ({
      ...state,
      information: action.payload,
    }),
    setPeople: (state, action: { payload: ResourceWinnerPerson[] }) => ({
      ...state,
      people: action.payload,
    }),
  },
});

export const {
  setMostVoted,
  setCurrentCategory,
  setResources,
  setInformation,
  setPeople,
  setHonorees,
  setPopular,
} = slice.actions;

export const mostVotedSelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.mostVoted;
export const currentCategorySelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.currentCategory;
export const resourcesSelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.resources;
export const ownCompanyResourcesSelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.ownCompanyResources;
export const informationSelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.information;
export const peopleSelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.people;
export const honoreesSelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.honorees;
export const popularSelector = (state: { resourceWinners: ResourceWinnersState }) =>
  state.resourceWinners.popular;

export const allOwnCompanyResourcesSelector = createSelector(
  ownCompanyResourcesSelector,
  (ownCompanyResources) =>
    Object.keys(ownCompanyResources).reduce((acc, category) => {
      acc.push(...ownCompanyResources[category]);
      return acc;
    }, [] as IResource[])
);

export const allHonoreesSlugsSelector = createSelector(honoreesSelector, (honorees) => {
  return Object.keys(honorees).reduce((acc, category) => {
    if (!honorees[category]) return acc;
    acc.push(...honorees[category].map((resource) => resource.slug));
    return acc;
  }, [] as string[]);
});

export default slice.reducer;
