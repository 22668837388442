import {
  PeopleBannerStepperIconContainer,
  PeopleBannerSteppersBottomRow,
  PeopleBannerStepperSeparator,
  PeopleBannerSteppersTopRow,
  PeopleBannerSteppersWrapper,
  PeopleBannerStepperTitle,
} from 'app/components/People/PeopleContent/PeopleBanner/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const ContactCTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 16px;

  border-radius: 8px;
  background: var(--color-data-skill-background);

  position: relative;
  z-index: 5;
`;

export const ContactCTASteppersWrapper = styled(PeopleBannerSteppersWrapper)`
  width: 100%;

  gap: 4px;

  ${BREAKPOINTS.tablet} {
    margin-top: 8px;
  }
`;

export const ContactCTASteppersTopRow = styled(PeopleBannerSteppersTopRow)`
  ${BREAKPOINTS.tablet} {
    width: 100%;

    display: grid;
    grid-template-columns: 24px 1fr 24px 1fr 24px;
  }
`;

export const ContactCTAStepperIconContainer = styled(PeopleBannerStepperIconContainer)`
  width: 24px;
  height: 24px;

  & > svg {
    min-width: 13px;
    min-height: 13px;
  }
`;

export const ContactCTAStepperSeparator = styled(PeopleBannerStepperSeparator)`
  width: 54px;

  ${BREAKPOINTS.tablet} {
    width: unset;
  }
`;

export const ContactCTASteppersBottomRow = styled(PeopleBannerSteppersBottomRow)`
  gap: 16.5px;
`;

export const ContactCTAStepperTitle = styled(PeopleBannerStepperTitle)`
  max-width: 62px;
  text-align: center;
`;

export const ContactCTAInformationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    gap: 4px;
  }
`;

export const ContactCTAInformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContactCTAInformationTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;

  color: var(--color-text);
`;

export const ContactCTAInformationDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;

  color: var(--colr-text);
`;

//BUTTON
export const ContactCTAButton = styled.button`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px 13px;

  border-radius: 100px;
  background: var(--color-primary);
`;

export const ContactCTAButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;

  color: #ffffff;
`;
