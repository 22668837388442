import styled from 'styled-components';

export const GlobalPersonWinnerShowcaseWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px 6px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);

  cursor: pointer;
`;

export const GlobalPersonWinnerShowcaseTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const GlobalPersonWinnerShowcaseSmallTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;

  color: rgba(23, 28, 51, 0.5);
`;

export const GlobalPersonWinnerShowcaseTitle = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  text-align: center;

  color: var(--color-text);
`;

export const GlobalPersonWinnerShowcaseResourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const GlobalPersonWinnerShowcaseResourceContainer = styled.div`
  width: 126px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const GlobalPersonWinnerShowcaseResourceTitle = styled.span`\
  height: 32px;

  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  text-align: center;

  color: var(--color-text);
`;
