import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HomeBaseDrawerWrapper } from 'app/components/CommonStyled/HomeDrawers/HomeBaseDrawer/styled';
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import Total from 'app/components/CommonStyled/Total';
import { TotalDataType } from 'app/components/CommonStyled/Total/interfaces';
import usePersonDetailsCommonGroundDrawer from 'app/components/DetailsPages/hooks/person/usePersonDetailsCommonGroundDrawer';
import { IPersonGlobalDrawerCounters } from 'app/components/DetailsPages/interfaces/person';
import { LocationIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { EmailIcon, Sparkle } from 'app/components/shared/icons';
import Calendar from 'app/components/shared/icons/calendar';
import PersonIcon from 'app/components/shared/icons/person-icon';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import useBehavioral from 'hooks/useBehavioral';
import { User } from 'interfaces';
import { userDataSelector } from 'store/reducers/user-reducer';
import { ThemeProvider } from 'styled-components';
import { getCompanyLogoUrl, singularOrPlural } from 'utils';

import usePersonDrawerData from '../hooks/usePersonDrawerData';
import GlobalPersonWinnerShowcase from './GlobalPersonWinnerShowcase';
import {
  GlobalPersonDrawerBasicInformationContainer,
  GlobalPersonDrawerBio,
  GlobalPersonDrawerBottomSection,
  GlobalPersonDrawerChildrenContainer,
  GlobalPersonDrawerCommonThingsBottomContainer,
  GlobalPersonDrawerCommonThingsPeopleAvatarsContainer,
  GlobalPersonDrawerCommonThingsSeeMoreButton,
  GlobalPersonDrawerCommonThingsSeeMoreButtonText,
  GlobalPersonDrawerCommonThingsSparkleContainer,
  GlobalPersonDrawerCommonThingsTitle,
  GlobalPersonDrawerCommonThingsTopContainer,
  GlobalPersonDrawerCommonThingsWrapper,
  GlobalPersonDrawerCompanyLogo,
  GlobalPersonDrawerContactButton,
  GlobalPersonDrawerContactButtonLabel,
  GlobalPersonDrawerContainer,
  GlobalPersonDrawerCopiedSign,
  GlobalPersonDrawerDataContainer,
  GlobalPersonDrawerDetail,
  GlobalPersonDrawerDetailText,
  GlobalPersonDrawerDetailsContainer,
  GlobalPersonDrawerEmail,
  GlobalPersonDrawerEmptyProfileText,
  GlobalPersonDrawerFullName,
  GlobalPersonDrawerInformationContainer,
  GlobalPersonDrawerMidSection,
  GlobalPersonDrawerProfileButton,
  GlobalPersonDrawerRole,
  GlobalPersonDrawerTopSection,
} from './styled';
import { theme as providedTheme } from './theme';

const ContactDrawer = lazy(() => import('app/components/Drawers/Contact'));
const PersonCommonGround = lazy(() => import('../PersonCommonGround/PersonCommonGround'));

interface GlobalPersonDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
  theme?: 'light' | 'dark';
  children?: React.ReactNode;
}

interface ThingInCommon {
  [TotalDataType.PEOPLE]?: number;
  [TotalDataType.SKILLS]?: number;
  [TotalDataType.PASSIONS]?: number;
  [TotalDataType.PROJECTS]?: number;
  [TotalDataType.COMPANIES]?: number;
  [TotalDataType.INDUSTRIES]?: number;
  [TotalDataType.COMMUNITIES]?: number;
}

export default function GlobalPersonDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
  theme = 'light',
  children = null,
}: GlobalPersonDrawerProps) {
  const { push } = useHistory();
  const query = usePersonDrawerData(slug);

  const slackOpenedBehavioral = useBehavioral('SLACK_OPENED');
  const linkedinOpenedBehavioral = useBehavioral('LINKEDIN_OPENED');
  const fullProfileOpenedBehavioral = useBehavioral('FULL_PROFILE_OPENED');
  const emailCopiedBehavioral = useBehavioral('EMAIL_COPIED');

  const [triggerClosing, setTriggerClosing] = useState<boolean>(false);
  const [thingsInCommon, setThingsInCommon] = useState<Array<ThingInCommon>>([]);
  const [mousePosition, setMousePosition] = useState<any>({ x: 0, y: 0 });
  const [emailCopied, setEmailCopied] = useState<boolean>(false);
  const [openContactDrawer, setOpenContactDrawer] = useState<boolean>(false);
  const [openCommonGroundDrawer, setOpenCommonGroundDrawer] = useState<boolean>(false);
  const commonGroundQuery = usePersonDetailsCommonGroundDrawer(slug, {
    enabled: openCommonGroundDrawer,
  });

  const userData: User = useSelector(userDataSelector);

  const { isLoading, data: person, isError } = query;

  const amountOfDetails = useMemo(() => {
    let amount = 0;

    if (person) {
      if (person?.pronouns) {
        amount++;
      }

      if (person?.work_residence_city) {
        amount++;
      }

      if (person?.dates) {
        amount++;
      }
    }

    return amount;
  }, [person]);

  useEffect(() => {
    let timeoutId = null;

    if (emailCopied) {
      timeoutId = setTimeout(() => {
        setEmailCopied(false);
      }, 1000);
    }

    () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [emailCopied]);

  const handleSlackClick = () => {
    slackOpenedBehavioral({ person_id: person?.uuid });
    window.open(`http://slack.com/app_redirect?channel=${person?.slack}`, '_blank');
  };
  const handleEmailClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    emailCopiedBehavioral({
      action_details: 'global drawer',
    });
    setMousePosition({ x: e.pageX, y: e.pageY });
    setEmailCopied(true);
    navigator.clipboard.writeText(person?.email);
  };

  const handleLinkedInClick = () => {
    linkedinOpenedBehavioral({ person_id: person?.uuid });

    if (!person?.linkedin.startsWith('https://')) {
      window.open(`https://${person?.linkedin}`, '_blank');
    } else {
      window.open(person?.linkedin, '_blank');
    }
  };

  const handleViewFullProfileClick = () => {
    fullProfileOpenedBehavioral({ person_id: person?.uuid });
    push(`/person/${person?.slug}`);
  };

  const handleContactClick = () => {
    setOpenContactDrawer(true);
  };

  const totalThingsInCommon = useMemo(() => {
    if (!person) return 0;

    return Object.values(person?.counters).reduce((prevValue, currValue) => {
      return prevValue + currValue;
    }, 0);
  }, [person]);

  const handleOpenCommonGroundDrawer = () => {
    setOpenCommonGroundDrawer(true);
  };

  const handleCommonGroundDrawerClose = () => {
    setOpenCommonGroundDrawer(false);
  };

  useEffect(() => {
    if (!isLoading && person) {
      setThingsInCommon(getTopThreeDataTypes(person?.counters));
    }
  }, [isLoading]);

  if ((isLoading || isError) && !comingFromList)
    return (
      <ThemeProvider theme={providedTheme[theme]}>
        <GlobalPersonDrawerContainer></GlobalPersonDrawerContainer>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={{ ...providedTheme[theme], hasChildren: children !== null }}>
      <GlobalPersonDrawerContainer
        comingFromList={comingFromList}
        triggerClosing={triggerClosing}
        isAlumni={person?.is_alumni}
      >
        {children !== null ? (
          <GlobalPersonDrawerChildrenContainer>{children}</GlobalPersonDrawerChildrenContainer>
        ) : null}
        <GlobalPersonDrawerTopSection>
          {comingFromList && (
            <BackArrow
              onClick={() => {
                setTriggerClosing(true);
                setTimeout(() => {
                  backToListAction();
                }, 500);
              }}
            />
          )}
          <GlobalPersonDrawerBasicInformationContainer>
            <PersonAvatar
              avatar={person?.avatar}
              size={88}
              name={person?.name.split(' ')[0]}
              lastName={person?.name.split(' ')[1]}
              highlighted
            />
            <GlobalPersonDrawerDataContainer>
              <GlobalPersonDrawerFullName>{person?.name}</GlobalPersonDrawerFullName>
              {person?.is_alumni && !person?.hide_alumni_label ? (
                <GlobalPersonDrawerRole isAlumni>
                  <span>
                    <GlobalPersonDrawerCompanyLogo
                      src={getCompanyLogoUrl(person?.company.slug)}
                      alt={`${person?.company.name}'s logo`}
                    />
                    ALUMNI
                  </span>
                </GlobalPersonDrawerRole>
              ) : (
                <GlobalPersonDrawerRole>
                  <span>
                    {person?.role_name || 'Works'} at{' '}
                    <GlobalPersonDrawerCompanyLogo
                      src={getCompanyLogoUrl(person?.company.slug)}
                      alt={`${person?.company.name}'s logo`}
                    />
                  </span>
                </GlobalPersonDrawerRole>
              )}
              {person?.email && !person?.is_alumni ? (
                <GlobalPersonDrawerEmail href={`mailto:${person?.email}`}>
                  {person?.email}
                </GlobalPersonDrawerEmail>
              ) : null}
            </GlobalPersonDrawerDataContainer>
          </GlobalPersonDrawerBasicInformationContainer>
          <GlobalPersonDrawerDetailsContainer amountOfDetails={amountOfDetails}>
            {person?.pronouns && (
              <GlobalPersonDrawerDetail>
                <PersonIcon width={16} height={16} fill={providedTheme[theme].primaryIcon} />
                <GlobalPersonDrawerDetailText>{person?.pronouns}</GlobalPersonDrawerDetailText>
              </GlobalPersonDrawerDetail>
            )}
            {person?.work_residence_city && (
              <GlobalPersonDrawerDetail>
                <LocationIcon width={16} height={16} fill={providedTheme[theme].primaryIcon} />
                <GlobalPersonDrawerDetailText>
                  {person?.work_residence_city}
                </GlobalPersonDrawerDetailText>
              </GlobalPersonDrawerDetail>
            )}
            {person?.dates && (
              <GlobalPersonDrawerDetail>
                <Calendar width={16} height={16} fill={providedTheme[theme].primaryIcon} />
                <GlobalPersonDrawerDetailText>
                  {dayjs(person?.dates.start_date).format('MMM D, YYYY')} -{' '}
                  {person?.dates.end_date
                    ? dayjs(person?.dates.end_date).format('MMM D, YYYY')
                    : 'now'}
                </GlobalPersonDrawerDetailText>
              </GlobalPersonDrawerDetail>
            )}
          </GlobalPersonDrawerDetailsContainer>
        </GlobalPersonDrawerTopSection>
        <GlobalPersonDrawerMidSection>
          {person?.is_alumni ? (
            <GlobalPersonDrawerInformationContainer>
              <GlobalPersonDrawerEmptyProfileText>
                {person?.name.split(' ')[0]} no longer works at {person?.company.name}.
                {person?.role_name
                  ? ` They
                previously had the role ${person?.role_name}.`
                  : null}
              </GlobalPersonDrawerEmptyProfileText>
            </GlobalPersonDrawerInformationContainer>
          ) : null}
          {person?.bio ? (
            <GlobalPersonDrawerInformationContainer>
              <GlobalPersonDrawerBio>{person?.bio}</GlobalPersonDrawerBio>
            </GlobalPersonDrawerInformationContainer>
          ) : null}
          {person?.empty_profile && !person?.is_alumni ? (
            <GlobalPersonDrawerInformationContainer>
              <GlobalPersonDrawerEmptyProfileText>
                {person?.name.split(' ')[0]}’s profile doesn’t have a lot added to it.....yet.{' '}
              </GlobalPersonDrawerEmptyProfileText>
            </GlobalPersonDrawerInformationContainer>
          ) : null}
          {person?.best_of_2024_resources.length > 0 ? (
            <GlobalPersonWinnerShowcase data={person} />
          ) : null}
          {!person?.is_alumni && thingsInCommon.length > 0 ? (
            <GlobalPersonDrawerCommonThingsWrapper>
              <GlobalPersonDrawerCommonThingsTopContainer>
                <GlobalPersonDrawerCommonThingsSparkleContainer top={-9} left={33}>
                  <Sparkle width={24} height={24} fill={'#FFC229'} />
                </GlobalPersonDrawerCommonThingsSparkleContainer>
                <GlobalPersonDrawerCommonThingsSparkleContainer right={-9} top={81}>
                  <Sparkle width={24} height={24} fill={'#FFC229'} />
                </GlobalPersonDrawerCommonThingsSparkleContainer>
                <GlobalPersonDrawerCommonThingsTitle>
                  YOU HAVE {totalThingsInCommon}{' '}
                  {singularOrPlural(totalThingsInCommon, 'THING', 'THINGS')} IN COMMON
                </GlobalPersonDrawerCommonThingsTitle>
                <GlobalPersonDrawerCommonThingsPeopleAvatarsContainer>
                  <PersonAvatar
                    highlighted
                    avatar={person?.avatar}
                    name={person?.name.split(' ')[0]}
                    lastName={person?.name.split(' ')[1]}
                    size={36}
                    extraStyle={{ zIndex: 1 }}
                  />
                  <PersonAvatar
                    highlighted
                    avatar={userData.profile_image}
                    name={userData.first_name}
                    lastName={userData.last_name}
                    size={36}
                  />
                </GlobalPersonDrawerCommonThingsPeopleAvatarsContainer>
              </GlobalPersonDrawerCommonThingsTopContainer>
              <GlobalPersonDrawerCommonThingsBottomContainer>
                {thingsInCommon.map((commonThing, index) => (
                  <React.Fragment key={index}>
                    <Total
                      type="with_side_icon"
                      dataType={Object.keys(commonThing)[0] as TotalDataType}
                      counter={commonThing[Object.keys(commonThing)[0]]}
                      theme={theme}
                    />
                  </React.Fragment>
                ))}
              </GlobalPersonDrawerCommonThingsBottomContainer>
              <GlobalPersonDrawerCommonThingsSeeMoreButton onClick={handleOpenCommonGroundDrawer}>
                <GlobalPersonDrawerCommonThingsSeeMoreButtonText>
                  See more
                </GlobalPersonDrawerCommonThingsSeeMoreButtonText>
              </GlobalPersonDrawerCommonThingsSeeMoreButton>
            </GlobalPersonDrawerCommonThingsWrapper>
          ) : null}
        </GlobalPersonDrawerMidSection>
        {!person?.empty_profile && !person?.is_alumni ? (
          <GlobalPersonDrawerBottomSection>
            <GlobalPersonDrawerProfileButton onClick={handleViewFullProfileClick}>
              OPEN PROFILE
            </GlobalPersonDrawerProfileButton>
            <GlobalPersonDrawerContactButton onClick={handleContactClick}>
              <EmailIcon width={18} height={15} fill="var(--color-primary)" />
              <GlobalPersonDrawerContactButtonLabel>CONTACT</GlobalPersonDrawerContactButtonLabel>
            </GlobalPersonDrawerContactButton>
          </GlobalPersonDrawerBottomSection>
        ) : null}
        {emailCopied
          ? ReactDOM.createPortal(
              <GlobalPersonDrawerCopiedSign top={mousePosition.y} left={mousePosition.x}>
                Copied!
              </GlobalPersonDrawerCopiedSign>,
              document.querySelector('#root')
            )
          : null}
      </GlobalPersonDrawerContainer>
      <AnimatePresence>
        {openContactDrawer ? (
          <motion.div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 4,
              transform: 'translateX(100%)',
            }}
            initial={{ transform: 'translateX(100%)' }}
            animate={{ transform: 'translateX(0%)' }}
            transition={{ duration: 0.4 }}
            exit={{ transform: 'translateX(100%)' }}
          >
            <Suspense fallback={<HomeBaseDrawerWrapper />}>
              <ContactDrawer
                initialStep={2}
                backToRecipient={() => setOpenContactDrawer(false)}
                selectedUuids={[person.uuid]}
              />
            </Suspense>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {openCommonGroundDrawer ? (
          <motion.div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 4,
              transform: 'translateX(100%)',
            }}
            initial={{ transform: 'translateX(100%)' }}
            animate={{ transform: 'translateX(0%)' }}
            transition={{ duration: 0.4 }}
            exit={{ transform: 'translateX(100%)' }}
          >
            <Suspense fallback={<HomeBaseDrawerWrapper />}>
              <PersonCommonGround
                query={commonGroundQuery}
                person={{ ...person, first_name: person.name.split(' ')[0] }}
                onBack={handleCommonGroundDrawerClose}
              />
            </Suspense>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </ThemeProvider>
  );
}

export function BackArrow({ onClick }: { onClick?: () => void }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ alignSelf: 'flex-start', cursor: 'pointer' }}
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="#D9D9D9"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55806 14.8169C9.80214 15.061 10.1979 15.061 10.4419 14.8169C10.686 14.5729 10.686 14.1771 10.4419 13.9331L7.13388 10.625L14.375 10.625C14.7202 10.625 15 10.3452 15 10C15 9.65482 14.7202 9.375 14.375 9.375L7.13388 9.375L10.4419 6.06694C10.686 5.82286 10.686 5.42714 10.4419 5.18306C10.1979 4.93898 9.80214 4.93898 9.55806 5.18306L5.18382 9.55729C5.18118 9.55992 5.17857 9.56258 5.17598 9.56525C5.05866 9.68634 5 9.84317 5 10C5 10.0847 5.01687 10.1655 5.04743 10.2392C5.07671 10.31 5.11956 10.3765 5.17598 10.4347C5.17854 10.4374 5.18113 10.44 5.18374 10.4426M9.55806 14.8169L5.18374 10.4426L9.55806 14.8169Z"
        fill="#1F2025"
      />
    </svg>
  );
}

function getTopThreeDataTypes(counters: IPersonGlobalDrawerCounters): Array<ThingInCommon> {
  const dataTypesInCommon = Object.keys(counters).reduce<Array<ThingInCommon>>(
    (prevValue, currValue) => {
      if (counters[currValue] && counters[currValue] !== 0) {
        prevValue.push({ [currValue]: counters[currValue] });
      }

      return prevValue;
    },
    []
  );

  if (dataTypesInCommon.length === 0) return [];

  const sortedDataTypesInCommon = dataTypesInCommon.sort(
    (a, b) => b[Object.keys(b)[0]] - a[Object.keys(a)[0]]
  );

  return sortedDataTypesInCommon.slice(0, 3);
}
