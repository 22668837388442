import React from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getDrawerData } from 'services/app';

export default function useHomeDrawerData(
  model: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `home-drawer-data-${model}`,
    async () => {
      const response = await getDrawerData(model);
      if (!response.ok) {
        throw new Error(response.originalError?.message);
      }

      return response.data;
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
