import React from 'react';

export default function ResourceSubmissionOneIcon({ width = 65, height = 66 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6653 24.8371L32.4531 11.3682L52.241 24.8371L32.4531 38.3059L12.6653 24.8371Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.6653 32.943L32.4531 19.4742L52.241 32.943L32.4531 46.4118L12.6653 32.943Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.6653 41.1247L32.4531 27.6558L52.241 41.1247L32.4531 54.5935L12.6653 41.1247Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.3768 13.1097L12.3768 13.1097C17.7641 7.72066 24.9261 4.75448 32.5454 4.75448C40.1647 4.75448 47.3283 7.72066 52.7157 13.1097L52.7864 13.039L52.7157 13.1097C58.1031 18.4971 61.0709 25.6607 61.0709 33.28C61.0709 40.8993 58.1047 48.0629 52.7157 53.4503L52.7864 53.521L52.7157 53.4503C47.3283 58.8376 40.1647 61.8055 32.5454 61.8055C24.9261 61.8055 17.7625 58.8393 12.3751 53.4503C6.98775 48.0613 4.02157 40.8976 4.02157 33.2783C4.02157 25.659 6.98775 18.4971 12.3768 13.1097ZM32.5454 3.88291C16.3105 3.88291 3.15 17.0434 3.15 33.2783C3.15 49.5133 16.3105 62.6737 32.5454 62.6737C48.7803 62.6737 61.9408 49.5133 61.9408 33.2783C61.9408 17.0434 48.7803 3.88291 32.5454 3.88291Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
