import styled from 'styled-components';

import { DataTypePillType } from './DataTypePill';

interface PillContainerProps {
  type: DataTypePillType;
  clickable: boolean;
  svgMinWidth: number;
}

export const PillContainer = styled.div<PillContainerProps>`
  width: fit-content;
  display: flex;
  flex-direciton: row;
  align-items: center;
  justify-content: center;

  padding: 2px 8px;
  background: ${({ type, theme }) => theme.pill[type]};
  border-radius: 25px;

  ${({ clickable }) => (clickable ? `cursor: pointer;` : `cursor: default;`)}

  & > :not(:last-child) {
    margin-right: 5px;
  }

  svg {
    min-width: ${({ svgMinWidth }) => svgMinWidth}px;
    width: ${({ svgMinWidth }) => svgMinWidth}px;
  }

  &:hover {
    background: ${({ type, theme }) => theme.pillHover[type] || theme.pill[type]};
  }
`;

interface PillTextProps {
  type: DataTypePillType;
}

export const PillText = styled.span<PillTextProps>`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: ${({ type, theme }) => theme.text[type]};
`;

export const PillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;

  & > :not(:last-child) {
    margin-right: 8px;
  }
  z-index: 1;
`;
