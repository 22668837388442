import React, { useContext, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import HomeDrawer from 'app/components/CommonStyled/HomeDrawers';
import Select, { ISelectItem } from 'app/components/CommonStyled/Select';
import SelectItem from 'app/components/CommonStyled/Select/SelectItem';
import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import { IndustryIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import ComponentError from 'app/components/DetailsPages/shared/ComponentError/ComponentError';
import { ByIndustry, ByMemberCompanyIndustries } from 'app/components/Home/interfaces';
import { DeltaIcon } from 'app/components/shared/icons';
import { DETAILS_PAGE_ACTION } from 'app/pages/NewDetailsPage/actions';
import { arrayToOxford, ordinal_suffix_of } from 'utils';

import { HomeRadarActiveWorkContext, HomeRadarActiveWorkInfo } from '../HomeRadarActiveWorkContext';
import { HomeRadarActiveWorkClientText } from '../styled';
import useRadarActiveWork from '../useRadarActiveWork';
import {
  HomeRadarActiveWorkFilterContainer,
  HomeRadarActiveWorkIndustriesList,
  HomeRadarActiveWorkIndustriesWrapper,
  HomeRadarActiveWorkIndustryContainer,
  HomeRadarActiveWorkIndustryLogoContainer,
  HomeRadarActiveWorkIndustryText,
  HomeRadarActiveWorkIndustryTextContainer,
  HomeRadarActiveWorkNewIndustries,
  HomeRadarActiveWorkSkeletonItem,
  HomeRadarActiveWorkSkeletonList,
  HomeRadarActiveWorkTotalsContainer,
  HomeRadarActiveWorkTotalsIndustries,
  HomeRadarActiveWorkTotalsIndustriesContainer,
  HomeRadarActiveWorkTotalsRow,
  HomeRadarActiveWorkTotalsTime,
  HomeRadarActiveWorkTotalsTimeContainer,
  HomeRadarActiveWorkTotalsTitle,
} from './styled';

export default function HomeRadarActiveWorkIndustry() {
  const query = useRadarActiveWork();
  const { isLoading, isError, data } = query;
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<string>('by_industry');
  const industryListRef = useRef<HTMLDivElement>(null);
  const { limit, industryComponent } = useContext<HomeRadarActiveWorkInfo>(
    HomeRadarActiveWorkContext
  );

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const totalIndustries = useMemo<string>(() => {
    if (isLoading) return '';
    if (data.industries.totals?.latest_industries > 0)
      return `+${data.industries.totals?.latest_industries}`;
    if (data.industries.totals?.latest_industries < 0)
      return `-${data.industries.totals?.latest_industries}`;

    return `${data.industries.totals?.latest_industries}`;
  }, [data]);

  const handleOptionChange = (option: ISelectItem<string>) => {
    setCurrentFilter(option.value);
    if (industryListRef.current) {
      industryListRef.current.scrollTop = 0;
    }
  };

  if (isError) return <ComponentError query={query} skeleton={<HomeRadarActiveWorkSkeleton />} />;

  if (isLoading) return <HomeRadarActiveWorkSkeleton />;

  return (
    <HomeRadarActiveWorkIndustriesWrapper>
      <HomeRadarActiveWorkTotalsContainer>
        <HomeRadarActiveWorkTotalsRow>
          <HomeRadarActiveWorkTotalsTitle>INDUSTRIES</HomeRadarActiveWorkTotalsTitle>
          <HomeRadarActiveWorkTotalsTimeContainer>
            <HomeRadarActiveWorkTotalsTime>last 30 days</HomeRadarActiveWorkTotalsTime>
            {data.industries.totals?.latest_industries > 0 ? <DeltaIcon /> : null}
          </HomeRadarActiveWorkTotalsTimeContainer>
        </HomeRadarActiveWorkTotalsRow>
        <HomeRadarActiveWorkTotalsRow>
          <HomeRadarActiveWorkTotalsIndustriesContainer>
            <IndustryIcon width={24} height={24} fill={'var(--color-data-industry-foreground)'} />
            <HomeRadarActiveWorkTotalsIndustries>
              {data.industries.totals.total_industries}
            </HomeRadarActiveWorkTotalsIndustries>
          </HomeRadarActiveWorkTotalsIndustriesContainer>
          <HomeRadarActiveWorkNewIndustries>{totalIndustries}</HomeRadarActiveWorkNewIndustries>
        </HomeRadarActiveWorkTotalsRow>
      </HomeRadarActiveWorkTotalsContainer>
      <HomeRadarActiveWorkFilterContainer>
        <Select label="View by" onOptionChange={handleOptionChange}>
          <SelectItem value="by_industry" defaultChecked>
            industry
          </SelectItem>
          <SelectItem value="by_member_company">member company</SelectItem>
        </Select>
      </HomeRadarActiveWorkFilterContainer>
      <HomeRadarActiveWorkIndustriesList ref={industryListRef}>
        {data.industries.details[currentFilter].length > 0 &&
          data.industries.details[currentFilter]
            .slice(0, limit ?? data.industries.details[currentFilter].length)
            .map((client) => {
              switch (currentFilter) {
                case 'by_industry':
                  return <HomeRadarActiveWorkIndustryByIndustry data={client} key={client.slug} />;
                case 'by_member_company':
                  return <HomeRadarActiveWorkIndustryByCompany data={client} key={client.slug} />;
                default:
                  return <></>;
              }
            })}
      </HomeRadarActiveWorkIndustriesList>
      {showDrawer ? (
        <SideDrawer closeDrawer={handleDrawerClose} withClose>
          <HomeDrawer model={'clients'} extraParams={{ withActiveFilter: true }} />
        </SideDrawer>
      ) : null}
      {industryComponent ?? null}
    </HomeRadarActiveWorkIndustriesWrapper>
  );
}

interface HomeRadarActiveWorkIndustryByIndustryProps {
  data: ByIndustry;
}

function HomeRadarActiveWorkIndustryByIndustry({
  data,
}: Readonly<HomeRadarActiveWorkIndustryByIndustryProps>) {
  const { push } = useHistory();

  const handleIndustryClick = () => {
    push(`/industry/${data.slug}`, {
      action: DETAILS_PAGE_ACTION.ACTIVE_PROJECTS_DRAWER_OPEN,
    });
  };

  const singleIndustryText = useMemo<string>(() => {
    const company = Object.values<any>(data.companies)[0];

    return ` has active work with ${company.name}`;
  }, [data]);

  const multipleCompaniesText = useMemo<string>(() => {
    const companies = Object.values<any>(data.companies).map((company) => company.name);

    return ` has active work with ${companies.length} companies; ${arrayToOxford(companies)}`;
  }, [data]);

  const newIndustryText = useMemo<string>(() => {
    const company = Object.values<any>(data.companies)[0];

    return `'s the newest industry ${company.name}'s working in.`;
  }, [data]);

  const fullText = useMemo<string>(() => {
    const isNewIndustry =
      Object.values<any>(data.companies).filter((company) => company.started).length > 0;

    if (Object.values<any>(data.companies).length === 1) {
      return isNewIndustry ? newIndustryText : singleIndustryText;
    }

    return multipleCompaniesText;
  }, [data]);

  return (
    <HomeRadarActiveWorkIndustryItem onClick={handleIndustryClick}>
      <HomeRadarActiveWorkIndustryText>
        <HomeRadarActiveWorkClientText highlighted>{data.name}</HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText>{fullText}</HomeRadarActiveWorkClientText>
      </HomeRadarActiveWorkIndustryText>
    </HomeRadarActiveWorkIndustryItem>
  );
}

interface HomeRadarActiveWorkIndustryByCompanyProps {
  data: ByMemberCompanyIndustries;
}

function HomeRadarActiveWorkIndustryByCompany({
  data,
}: Readonly<HomeRadarActiveWorkIndustryByCompanyProps>) {
  const { push } = useHistory();

  const handleCompanyClick = () => {
    push(`/member-company/${data.slug}`, {
      action: DETAILS_PAGE_ACTION.ACTIVE_INDUSTRIES_DRAWER_OPEN,
    });
  };

  const singleIndustryText = useMemo<string>(() => {
    const industry = Object.values<any>(data.industries)[0];
    const activeProjects = industry.active_project_count;
    const pastProjects = industry.past_project_count;

    const totalProjects = activeProjects + pastProjects;

    const hasNewProjects = industry.started;

    if (hasNewProjects) {
      if (totalProjects > 1) {
        return ` started their ${ordinal_suffix_of(totalProjects)} project in ${industry.name}.`;
      }

      return ` started working in ${industry.name} for the first time.`;
    } else {
      if (totalProjects > 1) {
        return ` is working on their ${ordinal_suffix_of(totalProjects)} project in ${
          industry.name
        }.`;
      }

      return ` is working in ${industry.name}.`;
    }
  }, [data]);

  const multipleIndustries = useMemo<string>(() => {
    const industries = Object.values<any>(data.industries).map((industry) => industry.name);

    return ` is currently working in ${industries.length} industries; ${arrayToOxford(
      industries
    )}.`;
  }, [data]);

  const fullText = useMemo<string>(() => {
    const industries = Object.values<any>(data.industries);

    if (industries.length === 1) {
      return singleIndustryText;
    }

    return multipleIndustries;
  }, [data]);

  return (
    <HomeRadarActiveWorkIndustryItem onClick={handleCompanyClick}>
      <HomeRadarActiveWorkIndustryText>
        <HomeRadarActiveWorkClientText highlighted>{data.name}</HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText>{fullText}</HomeRadarActiveWorkClientText>
      </HomeRadarActiveWorkIndustryText>
    </HomeRadarActiveWorkIndustryItem>
  );
}

interface HomeRadarActiveWorkIndustryItemProps {
  children?: React.ReactNode;
  onClick?: () => void;
}

function HomeRadarActiveWorkIndustryItem({
  children,
  onClick,
}: Readonly<HomeRadarActiveWorkIndustryItemProps>) {
  const handleClick = () => {
    if (onClick) {
      return onClick();
    }
  };

  return (
    <HomeRadarActiveWorkIndustryContainer onClick={handleClick}>
      <HomeRadarActiveWorkIndustryLogoContainer>
        <IndustryIcon fill={'var(--color-data-client-foreground)'} width={14} height={14} />
      </HomeRadarActiveWorkIndustryLogoContainer>
      <HomeRadarActiveWorkIndustryTextContainer>
        {children}
      </HomeRadarActiveWorkIndustryTextContainer>
    </HomeRadarActiveWorkIndustryContainer>
  );
}

function HomeRadarActiveWorkSkeleton() {
  return (
    <HomeRadarActiveWorkSkeletonList>
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
    </HomeRadarActiveWorkSkeletonList>
  );
}
