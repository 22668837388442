import { SliceCaseReducers, createSelector, createSlice } from '@reduxjs/toolkit';
import { IResource, IResourcePerson } from 'app/components/Resources/ResourceCard';
import { stringToSlug } from 'utils';

import { AllResourceVotingCategories } from '../resource-winners/resource-winners-reducer';

export const ResourceVotingCategoriesURLFriendly = {
  APPS_WEBSITES_AND_DIGITAL_EXPERIENCES: 'apps-websites-and-digital-experiences',
  AI_AND_EMERGING_TECH: 'ai-and-emerging-tech',
  MEDIA_AND_ADVERTISING: 'media-and-advertising',
  CREATIVE_AND_SPATIAL_DESIGN: 'creative-and-spatial-design',
  STRATEGY_BRAND_AND_COMMUNICATION: 'strategy-brand-and-communication',
  CULTURE_AND_INCLUSION: 'culture-and-inclusion',
  ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY: 'environmental-and-social-sustainability',
  PUBLIC_AND_SOCIETAL_IMPACT: 'public-and-societal-impact',
  OPERATIONAL_INNOVATION: 'operational-innovation',
  MY_COMPANY: 'my-company',
  MY_VOTES: 'my-votes',
} as const;

export type ResourceVotingCategoriesURLFriendly =
  (typeof ResourceVotingCategoriesURLFriendly)[keyof typeof ResourceVotingCategoriesURLFriendly];

export const ResourceVotingCategories = {
  APPS_WEBSITES_AND_DIGITAL_EXPERIENCES: 'Apps, Websites & Digital Experiences',
  AI_AND_EMERGING_TECH: 'AI & Emerging Tech',
  MEDIA_AND_ADVERTISING: 'Media & Advertising',
  CREATIVE_AND_SPATIAL_DESIGN: 'Creative & Spatial Design',
  STRATEGY_BRAND_AND_COMMUNICATION: 'Strategy, Brand & Communication',
  CULTURE_AND_INCLUSION: 'Culture & Inclusion',
  ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY: 'Environmental & Social Sustainability',
  PUBLIC_AND_SOCIETAL_IMPACT: 'Public & Societal Impact',
  OPERATIONAL_INNOVATION: 'Operational Innovation',
} as const;

export type ResourceVotingCategories =
  (typeof ResourceVotingCategories)[keyof typeof ResourceVotingCategories];

export const ResourceVotingCategoriesURLFriendlyToCategory = new Map<
  ResourceVotingCategoriesURLFriendly,
  ResourceVotingCategories | 'MY_COMPANY'
>([
  [
    ResourceVotingCategoriesURLFriendly.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
  ],
  [
    ResourceVotingCategoriesURLFriendly.AI_AND_EMERGING_TECH,
    ResourceVotingCategories.AI_AND_EMERGING_TECH,
  ],
  [
    ResourceVotingCategoriesURLFriendly.MEDIA_AND_ADVERTISING,
    ResourceVotingCategories.MEDIA_AND_ADVERTISING,
  ],
  [
    ResourceVotingCategoriesURLFriendly.CREATIVE_AND_SPATIAL_DESIGN,
    ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN,
  ],
  [
    ResourceVotingCategoriesURLFriendly.STRATEGY_BRAND_AND_COMMUNICATION,
    ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
  ],
  [
    ResourceVotingCategoriesURLFriendly.CULTURE_AND_INCLUSION,
    ResourceVotingCategories.CULTURE_AND_INCLUSION,
  ],
  [
    ResourceVotingCategoriesURLFriendly.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
  ],
  [
    ResourceVotingCategoriesURLFriendly.PUBLIC_AND_SOCIETAL_IMPACT,
    ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT,
  ],
  [
    ResourceVotingCategoriesURLFriendly.OPERATIONAL_INNOVATION,
    ResourceVotingCategories.OPERATIONAL_INNOVATION,
  ],
  [ResourceVotingCategoriesURLFriendly.MY_VOTES, null],
  [ResourceVotingCategoriesURLFriendly.MY_COMPANY, 'MY_COMPANY'],
]);

export const ResourceVotingCategoriesToURLFriendly = new Map<
  ResourceVotingCategories | 'MY_COMPANY',
  ResourceVotingCategoriesURLFriendly
>([
  [
    ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    ResourceVotingCategoriesURLFriendly.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
  ],
  [
    ResourceVotingCategories.AI_AND_EMERGING_TECH,
    ResourceVotingCategoriesURLFriendly.AI_AND_EMERGING_TECH,
  ],
  [
    ResourceVotingCategories.MEDIA_AND_ADVERTISING,
    ResourceVotingCategoriesURLFriendly.MEDIA_AND_ADVERTISING,
  ],
  [
    ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN,
    ResourceVotingCategoriesURLFriendly.CREATIVE_AND_SPATIAL_DESIGN,
  ],
  [
    ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
    ResourceVotingCategoriesURLFriendly.STRATEGY_BRAND_AND_COMMUNICATION,
  ],
  [
    ResourceVotingCategories.CULTURE_AND_INCLUSION,
    ResourceVotingCategoriesURLFriendly.CULTURE_AND_INCLUSION,
  ],
  [
    ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    ResourceVotingCategoriesURLFriendly.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
  ],
  [
    ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT,
    ResourceVotingCategoriesURLFriendly.PUBLIC_AND_SOCIETAL_IMPACT,
  ],
  [
    ResourceVotingCategories.OPERATIONAL_INNOVATION,
    ResourceVotingCategoriesURLFriendly.OPERATIONAL_INNOVATION,
  ],
  [null, ResourceVotingCategoriesURLFriendly.MY_VOTES],
  ['MY_COMPANY', ResourceVotingCategoriesURLFriendly.MY_COMPANY],
]);

export interface IResourceVotingModal {
  slug: string;
  show: boolean;
}

export interface ResourceVotingState {
  categories: AllResourceVotingCategories[];
  currentCategory: ResourceVotingCategories | 'MY_COMPANY';
  resources: {
    [key in ResourceVotingCategories]: IResource[];
  };
  votedResources: {
    [key in ResourceVotingCategories]: IResource;
  };
  resourcesByCompany: {
    [key in ResourceVotingCategories]: {
      [key: string]: IResource[];
    };
  };
  ownCompanyResources: {
    [key in ResourceVotingCategories]: IResource[];
  };
  modal: IResourceVotingModal;
  showInformationModal: boolean;
}

export const slice = createSlice<ResourceVotingState, SliceCaseReducers<ResourceVotingState>>({
  name: 'resourceVoting',
  initialState: {
    categories: [
      ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
      ResourceVotingCategories.AI_AND_EMERGING_TECH,
      ResourceVotingCategories.MEDIA_AND_ADVERTISING,
      ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN,
      ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
      ResourceVotingCategories.CULTURE_AND_INCLUSION,
      ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
      ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT,
      ResourceVotingCategories.OPERATIONAL_INNOVATION,
    ],
    currentCategory: ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    resources: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
    },
    votedResources: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: null,
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: null,
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: null,
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: null,
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: null,
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: null,
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: null,
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: null,
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: null,
    },
    resourcesByCompany: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: {},
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: {},
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: {},
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: {},
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: {},
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: {},
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: {},
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: {},
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: {},
    },
    ownCompanyResources: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
    },
    modal: {
      slug: null,
      show: false,
    },
    showInformationModal: false,
  },
  reducers: {
    setCurrentCategory: (state, action: { payload: ResourceVotingCategories }) => ({
      ...state,
      currentCategory: action.payload,
    }),
    setResources: (
      state,
      action: {
        payload: {
          resources: {
            [key in ResourceVotingCategories]: IResource[];
          };
          company: string;
        };
      }
    ) => {
      const resourcesByCompany = {
        [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: {},
        [ResourceVotingCategories.AI_AND_EMERGING_TECH]: {},
        [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: {},
        [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: {},
        [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: {},
        [ResourceVotingCategories.CULTURE_AND_INCLUSION]: {},
        [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: {},
        [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: {},
        [ResourceVotingCategories.OPERATIONAL_INNOVATION]: {},
      };

      let ownCompanyResourcesTemp = {
        [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
        [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
        [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
        [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
        [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
        [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
        [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
        [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
        [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
      };

      Object.keys(action.payload.resources).forEach((category) => {
        const resources: IResource[] = action.payload.resources[category];

        const data = resources.reduce(
          (acc, resource) => {
            resource.contributors.companies.forEach((company) => {
              if (!acc.resources[company.slug]) {
                acc.resources[company.slug] = [];
              }

              if (company.slug === action.payload.company) {
                if (!acc.ownCompanyResources[category]) {
                  acc.ownCompanyResources[category] = [];
                }

                if (!acc.ownCompanyResources[category].find((r) => r.slug === resource.slug)) {
                  acc.ownCompanyResources[category].push(resource);
                }
              }

              acc.resources[company.slug].push(resource);
            });

            resource.contributors.people.forEach((person: any) => {
              if (!acc.resources[person.company_slug]) {
                acc.resources[person.company_slug] = [];
              }

              if (!acc.resources[person.company_slug].find((r) => r.slug === resource.slug)) {
                acc.resources[person.company_slug].push(resource);
              }

              if (person.company_slug === action.payload.company) {
                if (!acc.ownCompanyResources[category]) {
                  acc.ownCompanyResources[category] = [];
                }

                if (
                  acc.ownCompanyResources[category] &&
                  !acc.ownCompanyResources[category].find((r) => r.slug === resource.slug)
                ) {
                  acc.ownCompanyResources[category].push(resource);
                }
              }
            });

            return acc;
          },
          {
            resources: {},
            ownCompanyResources: {},
          }
        );

        resourcesByCompany[category] = data.resources;
        ownCompanyResourcesTemp[category] = data.ownCompanyResources[category] || [];
      });

      return {
        ...state,
        resources: action.payload.resources,
        resourcesByCompany,
        ownCompanyResources: ownCompanyResourcesTemp,
      };
    },
    setVotedCategory: (
      state,
      action: {
        payload: {
          category: ResourceVotingCategories;
          resource: IResource;
        };
      }
    ) => ({
      ...state,
      votedResources: {
        ...state.votedResources,
        [action.payload.category]: action.payload.resource,
      },
    }),
    setVotedResources: (
      state,
      action: {
        payload: {
          [key in ResourceVotingCategories]: IResource;
        };
      }
    ) => ({
      ...state,
      votedResources: action.payload,
    }),
    pushVotedResource: (
      state,
      action: {
        payload: {
          category: ResourceVotingCategories;
          resource: IResource;
        };
      }
    ) => ({
      ...state,
      votedResources: {
        ...state.votedResources,
        [action.payload.category]: action.payload.resource,
      },
    }),
    setModal: (
      state,
      action: {
        payload: {
          slug: string;
          show: boolean;
        };
      }
    ) => ({
      ...state,
      modal: action.payload,
    }),
    setShowInformationModal: (state, action: { payload: boolean }) => ({
      ...state,
      showInformationModal: action.payload,
    }),
  },
});

export const {
  setCurrentCategory,
  setResources,
  setVotedCategory,
  setVotedResources,
  pushVotedResource,
  setModal,
  setShowInformationModal,
} = slice.actions;

export const categoriesSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.categories;

export const currentCategorySelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.currentCategory;

export const resourcesSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.resources;

export const votedResourcesSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.votedResources;

export const modalSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.modal;

export const showInformationModalSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.showInformationModal;

export const resourcesByCompanySelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.resourcesByCompany;

export const ownCompanyResourcesSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.ownCompanyResources;

export const filledResourcesSelector = createSelector(
  votedResourcesSelector,
  (votedResources) =>
    Object.keys(votedResources).filter(
      (category) => votedResources[category]
    ) as AllResourceVotingCategories[]
);

export const allVotedResourcesSelector = createSelector(votedResourcesSelector, (votedResources) =>
  Object.keys(votedResources).reduce((acc, category) => {
    if (votedResources[category]) {
      acc.push(votedResources[category]);
    }

    return acc;
  }, [] as IResource[])
);

export const allOwnCompanyResourcesSelector = createSelector(
  ownCompanyResourcesSelector,
  (ownCompanyResources) =>
    Object.keys(ownCompanyResources).reduce((acc, category) => {
      acc.push(...ownCompanyResources[category]);
      return acc;
    }, [] as IResource[])
);

export default slice.reducer;
