import React from 'react';
import { useHistory } from 'react-router-dom';

import { IPersonGlobalDrawerResponse } from 'app/components/DetailsPages/interfaces/person';
import {
  resourceSubmissionCategoryToHonoreeIcon,
  resourceSubmissionCategoryToNomineeIcon,
  resourceSubmissionCategoryToWinnerIcon,
} from 'app/components/Home/HomeResourceWinners/constants';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import { AllResourceVotingCategories } from 'store/reducers/resource-winners/resource-winners-reducer';
import { orderResourcesByBestOf2024 } from 'utils';

import {
  GlobalPersonWinnerShowcaseResourceContainer,
  GlobalPersonWinnerShowcaseResourceTitle,
  GlobalPersonWinnerShowcaseResourceWrapper,
  GlobalPersonWinnerShowcaseSmallTitle,
  GlobalPersonWinnerShowcaseTitle,
  GlobalPersonWinnerShowcaseTitleContainer,
  GlobalPersonWinnerShowcaseWrapper,
} from './styled';

const bestOf2024ToIconSize = new Map<
  'winner' | 'honoree' | 'nominee',
  { width: number; height: number }
>([
  ['winner', { width: 78, height: 93 }],
  ['honoree', { width: 78, height: 81 }],
  ['nominee', { width: 78, height: 79 }],
]);

interface GlobalPersonWinnerShowcaseProps {
  data: IPersonGlobalDrawerResponse;
}

export default function GlobalPersonWinnerShowcase({ data }: GlobalPersonWinnerShowcaseProps) {
  const { push } = useHistory();
  const personDrawerBo24ClickedBehavioralFunction = useBehavioral(
    BEHAVIORALS.PERSON_DRAWER_BO24_CLICKED,
    { related_object_uuid: data.uuid }
  );

  const handleClick = (e) => {
    personDrawerBo24ClickedBehavioralFunction();
    e.stopPropagation();
    e.preventDefault();
    push(`/person/${data.slug}#stories_and_highlights`);
  };

  return (
    <GlobalPersonWinnerShowcaseWrapper onClick={handleClick}>
      <GlobalPersonWinnerShowcaseTitleContainer>
        <GlobalPersonWinnerShowcaseSmallTitle>
          BEST OF 2024 SHOWCASE
        </GlobalPersonWinnerShowcaseSmallTitle>
        <GlobalPersonWinnerShowcaseTitle>
          {data.name.split(' ')[0]}’s Nominations
        </GlobalPersonWinnerShowcaseTitle>
      </GlobalPersonWinnerShowcaseTitleContainer>
      <GlobalPersonWinnerShowcaseResourceWrapper>
        {orderResourcesByBestOf2024(data.best_of_2024_resources).map((resource) => (
          <GlobalPersonWinnerShowcaseResourceContainer key={resource.slug}>
            {React.cloneElement(
              resource.best_of_2024 === 'winner'
                ? resourceSubmissionCategoryToWinnerIcon.get(
                    resource.resource_submission_category.name as AllResourceVotingCategories
                  )
                : resource.best_of_2024 === 'honoree'
                ? resourceSubmissionCategoryToHonoreeIcon.get(
                    resource.resource_submission_category.name as AllResourceVotingCategories
                  )
                : resourceSubmissionCategoryToNomineeIcon.get(
                    resource.resource_submission_category.name as AllResourceVotingCategories
                  ),
              bestOf2024ToIconSize.get(resource.best_of_2024 || 'nominee')
            )}
            <GlobalPersonWinnerShowcaseResourceTitle>
              {resource.resource_submission_category.name}
            </GlobalPersonWinnerShowcaseResourceTitle>
          </GlobalPersonWinnerShowcaseResourceContainer>
        ))}
      </GlobalPersonWinnerShowcaseResourceWrapper>
    </GlobalPersonWinnerShowcaseWrapper>
  );
}
