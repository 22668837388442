import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import CollectiveCompanies from 'app/components/CommonStyled/CollectiveCompanies';
import CompanyLogo from 'app/components/CommonStyled/CompanyLogo';
import useHomeDrawerData from 'app/components/CommonStyled/HomeDrawers/HomeBaseDrawer/useHomeDrawerData';
import useOnboardingData from 'app/components/Onboarding/hooks/useOnboardingData';
import { KyuTeam } from 'app/components/Onboarding/steps/Two';
import useIsMobile from 'hooks/useIsMobile';
import { searchLensesSelector } from 'store/reducers/user-reducer';
import { singularOrPlural } from 'utils';

import AboutKyuOsExecutiveCommittee from './AboutKyuOsExecutiveCommittee';
import {
  AboutKyuOsIntroductionKyuTeamContainer,
  AboutKyuOsIntroductionKyuTeamDescription,
  AboutKyuOsIntroductionKyuTeamPartnerCompaniesContainer,
  AboutKyuOsIntroductionKyuTeamTitle,
  AboutKyuOsIntroductionText,
  AboutKyuOsIntroductionWrapper,
} from './styled';

export default function AboutKyuOsIntroduction() {
  const query = useOnboardingData();
  const isMobile = useIsMobile();
  const { isLoading, isError, data } = useHomeDrawerData('kyu_companies');
  const searchLenses: any = useSelector(searchLensesSelector);

  const partnerCompanies = useMemo(() => {
    if (!data) return [];

    return data.filter((company) => company.company_type === 'partner');
  }, [data]);

  return (
    <AboutKyuOsIntroductionWrapper>
      <AboutKyuOsIntroductionText>
        The kyu Collective currently connects {searchLenses?.members} best-in-industry companies
        that offer services in design, strategy, advertising, web development, data science, talent
        recruitment, and public affairs. In addition, there{' '}
        {singularOrPlural(
          searchLenses?.partners,
          'is 1 partner company that is part of our extended network',
          `are ${searchLenses?.partners} partner companies that are part of our extended network`
        )}
        .
      </AboutKyuOsIntroductionText>
      <AboutKyuOsIntroductionKyuTeamTitle>MEMBER COMPANIES</AboutKyuOsIntroductionKyuTeamTitle>
      <CollectiveCompanies openDrawer />
      {partnerCompanies.length > 0 ? (
        <>
          <AboutKyuOsIntroductionKyuTeamTitle>PARTNER COMPANIES</AboutKyuOsIntroductionKyuTeamTitle>
          <AboutKyuOsIntroductionKyuTeamPartnerCompaniesContainer>
            {partnerCompanies.map((company) => (
              <CompanyLogo key={company.slug} size={68} slug={company.slug} useRounded openDrawer />
            ))}
          </AboutKyuOsIntroductionKyuTeamPartnerCompaniesContainer>
        </>
      ) : null}
      <AboutKyuOsIntroductionText>
        Within the Collective, there are lots of opportunities for growing your network, tapping
        into great resources and collaborating on exciting community and client initiatives.
      </AboutKyuOsIntroductionText>
      <AboutKyuOsIntroductionKyuTeamContainer>
        <AboutKyuOsExecutiveCommittee />
      </AboutKyuOsIntroductionKyuTeamContainer>
      <AboutKyuOsIntroductionKyuTeamContainer
        style={isMobile ? { padding: '48px 0px 80px 0px' } : {}}
      >
        <KyuTeam query={query} />
        <AboutKyuOsIntroductionKyuTeamDescription>
          There is a small kyu team that operates mainly out of New York and London and manages this
          platform
        </AboutKyuOsIntroductionKyuTeamDescription>
      </AboutKyuOsIntroductionKyuTeamContainer>
      <AboutKyuOsIntroductionText>
        Whether you’ve recently joined or you’ve been here for awhile, there’s a lot to uncover
        about the power of the kyu Collective.
      </AboutKyuOsIntroductionText>
    </AboutKyuOsIntroductionWrapper>
  );
}
