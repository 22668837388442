import React from 'react';

export default function ResourceSubmissionFiveHonoreeIcon({ width = 78, height = 81 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 78 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7097_17075)">
        <path
          d="M35.7636 2.88524C37.4398 0.518269 40.9522 0.518269 42.6284 2.88524C44.288 5.22882 47.4903 5.86581 49.9204 4.33572C52.3748 2.79036 55.6198 4.13451 56.2626 6.96275C56.899 9.76303 59.6138 11.577 62.4445 11.0934C65.3034 10.6049 67.7871 13.0885 67.2986 15.9475C66.815 18.7781 68.6289 21.4929 71.4292 22.1294C74.2575 22.7721 75.6016 26.0172 74.0563 28.4716C72.5262 30.9017 73.1631 34.104 75.5067 35.7636C77.8737 37.4398 77.8737 40.9522 75.5067 42.6284C73.1631 44.288 72.5262 47.4903 74.0563 49.9204C75.6016 52.3748 74.2575 55.6199 71.4292 56.2626C68.6289 56.899 66.815 59.6138 67.2986 62.4445C67.7871 65.3034 65.3034 67.7871 62.4445 67.2986C59.6138 66.815 56.899 68.6289 56.2626 71.4292C55.6199 74.2575 52.3748 75.6016 49.9204 74.0563C47.4903 72.5262 44.288 73.1631 42.6284 75.5067C40.9522 77.8737 37.4398 77.8737 35.7636 75.5067C34.104 73.1631 30.9017 72.5262 28.4716 74.0563C26.0172 75.6016 22.7721 74.2575 22.1294 71.4292C21.4929 68.6289 18.7781 66.815 15.9475 67.2986C13.0886 67.7871 10.6049 65.3034 11.0934 62.4445C11.577 59.6138 9.76303 56.899 6.96275 56.2626C4.13451 55.6198 2.79036 52.3748 4.33572 49.9204C5.86581 47.4903 5.22882 44.288 2.88524 42.6284C0.518269 40.9522 0.518269 37.4398 2.88524 35.7636C5.22882 34.104 5.86581 30.9017 4.33572 28.4716C2.79036 26.0172 4.13451 22.7721 6.96275 22.1294C9.76303 21.4929 11.577 18.7781 11.0934 15.9475C10.6049 13.0886 13.0886 10.6049 15.9475 11.0934C18.7781 11.577 21.4929 9.76303 22.1294 6.96275C22.7721 4.13451 26.0172 2.79036 28.4716 4.33572C30.9017 5.86581 34.104 5.22882 35.7636 2.88524Z"
          fill="#F15A42"
          stroke="white"
          strokeWidth="1.2"
        />
        <circle cx="39" cy="39" r="29" fill="#F15A42" />
        <path
          d="M19.1621 19.1622L19.1622 19.1621C24.5401 13.7825 31.6894 10.8216 39.2954 10.8216C46.9014 10.8216 54.0524 13.7825 59.4303 19.1621L59.5364 19.0561L59.4303 19.1621C64.8083 24.5401 67.7709 31.6911 67.7709 39.2971C67.7709 46.9031 64.81 54.054 59.4304 59.432L59.5364 59.5381L59.4303 59.432C54.0524 64.81 46.9014 67.7726 39.2954 67.7726C31.6894 67.7726 24.5384 64.8117 19.1605 59.432C13.7825 54.0524 10.8216 46.9014 10.8216 39.2954C10.8216 31.6894 13.7825 24.5401 19.1621 19.1622ZM39.2954 9.85C23.0328 9.85 9.85 23.0328 9.85 39.2954C9.85 55.558 23.0328 68.7408 39.2954 68.7408C55.558 68.7408 68.7408 55.558 68.7408 39.2954C68.7408 23.0328 55.558 9.85 39.2954 9.85Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
        <path d="M61 38.4988H18" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" />
        <path d="M39.5 60V17" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" />
        <path
          d="M54.7015 53.7013L24.2969 23.2966"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M24.2969 53.7013L54.7015 23.2966"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M59.3864 46.664L19.6094 30.3337"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M31.3359 58.3873L47.6662 18.6104"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M47.7903 58.3374L31.2109 18.6624"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M19.6641 46.7886L59.3391 30.2092"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_7097_17075">
          <rect width="78" height="81" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
